import React, { FC, PropsWithChildren, useMemo } from "react";
import { initializeContext } from "../utils/initialize-context";
import { Elements } from "@stripe/react-stripe-js";
import { useBrandingContext } from "./BrandingContext";
import { loadStripe } from "@stripe/stripe-js";

export const [StripeContext, useStripeContext, StripeContextProvider] =
	initializeContext("Stripe");

export const StripeProvider: FC<PropsWithChildren> = ({
	children,
}): JSX.Element => {
	const { info} = useBrandingContext();
	
	const stripePublicKey = info?.stripe_public_key__c;
	const accountId = info?.stripe_account_id;
	const stripePromise = useMemo(
		() => stripePublicKey ? loadStripe(stripePublicKey, { stripeAccount: accountId }) : null,
		[stripePublicKey],
	);

	return (
		<StripeContextProvider value={{ }}>
			<Elements stripe={stripePromise}>
				{children}
			</Elements>
		</StripeContextProvider>
	);
};
