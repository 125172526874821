import { EEndpointType, IStripeSetupIntentElementResponse } from "amp";
import { useCallback } from "react";
import { useApi } from "src/hooks/useApi";

export const useMembershipSignupPayments = () => {
	const { post } = useApi(EEndpointType.Anonymous, "payment");

	const getSetupIntentClientSecret = useCallback(
		async (customerId: string) => {
			const response = await post<
				{ customerId: string },
				IStripeSetupIntentElementResponse
			>("create-setup-intent", { customerId });
			return response.setupIntent;
		},
		[post],
	);

	const updateDefaultPaymentMethod = useCallback(
		async (customerId: string, paymentMethodId: string) => {
			await post<{ customerId: string; paymentMethodId: string }, void>(
				"change-default-payment-method",
				{ customerId, paymentMethodId },
			);
		},
		[post],
	);

    return {
        getSetupIntentClientSecret,
        updateDefaultPaymentMethod
    }
};
