import React, { FC, PropsWithChildren } from "react";
import { EmailUnsubscribeProvider } from "../contexts/EmailUnsubscribeContext";

export const EmailUnsubscribeProviders: FC<PropsWithChildren> = ({
	children,
}): JSX.Element => {
	return (
		<EmailUnsubscribeProvider>{children}</EmailUnsubscribeProvider>
	);
};
