import React, { FC, PropsWithChildren } from "react";
import {  AppInsightsContext, AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../utils/app-insights";
import { SomethingWentWrong } from "../components/ui-elements/SomethingWentWrong";

export const AppInsightsProvider: FC<PropsWithChildren> = ({
	children,
}): JSX.Element => {
	return (
		<AppInsightsContext.Provider value={reactPlugin}>
			<AppInsightsErrorBoundary 
				onError={() => <SomethingWentWrong />}
				appInsights={reactPlugin}
			>
				<>{children}</>
			</AppInsightsErrorBoundary>
		</AppInsightsContext.Provider>
	);
};
