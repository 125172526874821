import { useTheme } from '@material-ui/core/styles';
import { Theme } from '../styles/theme';

export const ThemeVariables = () => {
  const theme = useTheme() as Theme;
  
  // Create CSS variable style block
  const cssVariables = `
    :root {
      --primary: ${theme.palette.primary.main};
      --primary-light: ${theme.palette.primary.light};
      --primary-dark: ${theme.palette.primary.dark};
      
      --secondary: ${theme.palette.secondary.main};
      --secondary-light: ${theme.palette.secondary.light};
      --secondary-dark: ${theme.palette.secondary.dark};
      
      --error: ${theme.palette.error.main};
      --error-light: ${theme.palette.error.light};
      
      --warning: ${theme.palette.warning.main};
      --success: ${theme.palette.success.main};
      
      --text-primary: ${theme.palette.text.primary};
      --text-secondary: ${theme.palette.text.secondary};
      
      --background-default: ${theme.palette.background.default};
      --background-paper: ${theme.palette.background.paper};
      --background-dark: ${theme.palette.background.dark};

      --color-border-default: #DEE3EE;
      --color-surface: #FFFFFF;
      --color-surface-dark: #F8F9FC;
      --lighter-gray: #f5f5f5;
      --light-gray: #e6e6e6;
      --medium-gray: #D8D8D8;
      --medium-gray-02: #c2c2c2;
      --gray: #7b7c7e;
      --dark-gray: #616161;
    }
  `;

  return <style>{cssVariables}</style>;
};