import React, { FC, PropsWithChildren, useEffect, useState } from "react";
import environment from "../environment";
import { IsLoading } from "../components/loading/IsLoading";
import { initializeContext } from "../utils/initialize-context";
import { isLocalHost } from "../utils/isLocalHost";
import { useNavigate, useSearchParams } from "react-router-dom";

interface ITenantContext {
	tenantKey: string;
}

export const [TenantContext, useTenantContext, TenantContextProvider] =
	initializeContext<ITenantContext>("Tenant");

export const TenantProvider: FC<PropsWithChildren<ITenantContext>> = ({
	children,
}): JSX.Element => {
	const [tenantKey, setTenantKey] = useState<string | undefined>(undefined);
	const [urlSearchParams] = useSearchParams();
	const tenantKeyFromUrl = urlSearchParams.get("tenantKey");
	const navigate = useNavigate();

	useEffect(() => {
		const newTenantKey = isLocalHost() && !tenantKeyFromUrl ? environment.localhost.tenantKey : tenantKeyFromUrl;
		if (newTenantKey !== tenantKey) {
			setTenantKey(newTenantKey as string);
		}
	}, [tenantKeyFromUrl]);

	useEffect(() => {
		if (isLocalHost() && !tenantKeyFromUrl) {
			const urlSearchParams = new URLSearchParams(window.location.search);
			urlSearchParams.set("tenantKey", environment.localhost.tenantKey);
			navigate(`?${urlSearchParams.toString()}`);
		}
	}, [tenantKey]);

	if (!tenantKey) {
		return <IsLoading />;
	}

	return (
		<TenantContextProvider value={{ tenantKey }}>
			{children}
		</TenantContextProvider>
	);
};