import React, { FC, PropsWithChildren, useEffect } from "react";
import { useLoadingContext } from "../../contexts/LoadingContext";

export interface IIsLoadingProps {
	text?: string;
}

export const IsLoading: FC<PropsWithChildren<IIsLoadingProps>> = ({ text, children }) => {
	// Get loading context
	const { setLoadingText } = useLoadingContext();
	// Set loading text
	useEffect(() => {
		// Set loading text
		setLoadingText(text);
		// Return clear loading text
		return () => setLoadingText(undefined);
	}, [setLoadingText, text]);
	// Return children
	return <>{children}</>;
};
