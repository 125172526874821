import { useCallback, useMemo } from "react";
import { useTenantContext } from "../contexts/TenantContext";
import { useNavigate, useSearchParams } from "react-router-dom";

export const useTenantRouter = () => {
	const { tenantKey } = useTenantContext();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const push = useCallback(
		(route: string) => {
			navigate(`${route}/?tenantKey=${tenantKey}`);
		},
		[tenantKey],
	);

	const replace = useCallback(
		(route: string) => {
			navigate(`${route}/?tenantKey=${tenantKey}`);
		},
		[tenantKey],
	);

	const query = useMemo(
		() => Object.fromEntries(searchParams.entries()),
		[searchParams],
	);

	return {
		push,
		replace,
		query,
	};
};
