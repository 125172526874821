import { EEndpointType } from "amp";
import { useCallback } from "react";
import { useApi } from "src/hooks/useApi";

export const useValidateReferralCode = () => {
	const { post } = useApi(EEndpointType.Anonymous, "invoice");

	const valiadatePromoCode = useCallback(
		(code: string) =>
			post<{ referralCode: string }, { valid: boolean }>(
				"validate-referral-code",
				{
					referralCode: code,
				},
			),
		[post],
	);

	return valiadatePromoCode;
};
