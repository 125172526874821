/* eslint-disable react-hooks/exhaustive-deps */
import { DynamicSql, EAccountApproach, IKiosk, IProductWithJson } from "amp";
import {
	FC,
	PropsWithChildren,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { useParams } from "react-router-dom";
import { IsLoading } from "../../../components/loading/IsLoading";
import { useFetchDataDynamic } from "../../../hooks/useFetchDataDynamic";
import { useGetAccountApproach } from "../../../hooks/useGetAccountApproach";
import { initializeContext } from "../../../utils/initialize-context";
import { useGetNonsubscribedUserByPhoneNumber } from "../hooks/useGetPhoneNumberExists";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { useTenantRouter } from "src/hooks/useTenantRouter";

export enum EMembershipPurchaseScreen {
	Info,
	Payment,
	Success,
}

export interface IUserData {
	userId: string;
	accountId: string;
	stripeCustomerId: string;
	email?: string;
	name?: string;
	vehicleId?: number;
}

interface IMembershipPurchaseContext {
	loading: boolean;
	accountApproach: EAccountApproach;
	kiosk: IKiosk;
	initialCustomerPhoneNumber: string;
	product: IProductWithJson | undefined;
	userData: IUserData | undefined;
	setUserData: (userData: IUserData) => void;
	screen: EMembershipPurchaseScreen;
	setScreen: (screen: EMembershipPurchaseScreen) => void;
	priceInDollars?: string;
}

export const [
	MembershipPurchaseContext,
	useMembershipPurchaseContext,
	MembershipPurchaseContextProvider,
] = initializeContext<IMembershipPurchaseContext>("Membership Purchase");

export const MembershipPurchaseProvider: FC<PropsWithChildren> = ({
	children,
}): JSX.Element => {
	// States
	const [userData, setUserData] = useState<IUserData | undefined>();
	const [screen, setScreen] = useState<EMembershipPurchaseScreen>(
		EMembershipPurchaseScreen.Info,
	);
	// HOOKS
	const navigate = useTenantRouter();
	// Get params
	const {
		kioskId,
		productId,
		phoneNumber: initialCustomerPhoneNumber,
	} = useParams() as {
		kioskId: string;
		productId: string;
		phoneNumber: string;
	};
	// Get account approach
	const { accountApproach, loading: loadingAccountApproach } =
		useGetAccountApproach();
	// Get nonsubscribed user
	const getNonsubscribedUser = useGetNonsubscribedUserByPhoneNumber();

	const getAmpUserIfExists = useCallback(
		async (phoneNumber: string) => {
			try {
			const userData = await getNonsubscribedUser(phoneNumber);
			if (userData) {
				setUserData({
					userId: userData.userId,
					accountId: userData.accountId,
					stripeCustomerId: userData.stripeCustomerId,
					email: userData.email ?? undefined,
					name: userData.name ?? undefined,
				});
			}
		} catch (error: unknown) {
			if (error instanceof AxiosError) {
				if (error.response?.status === 401) {
					navigate.push("/membership-purchase/unauthorized");
				}
			}
		}
		},
		[getNonsubscribedUser],
	);

	useEffect(() => {
		getAmpUserIfExists(initialCustomerPhoneNumber);
	}, [initialCustomerPhoneNumber]);
	// Fetch product
	const { data: products, loading: loadingProduct } = useFetchDataDynamic(
		new DynamicSql("products", (product) =>
			product
				.addCondition(product.eq("id", parseInt(productId)))
				.addCondition(product.eq("active", true))
				.addJoin("prices", (prices) =>
					prices.addCondition(prices.eq("active", true)),
				),
		),
	);
	// Fetch kiosk
	const { data: kiosks, loading: loadingKiosk } = useFetchDataDynamic(
		new DynamicSql("kiosks", (kiosk) =>
			kiosk
				.addCondition(kiosk.eq("id", parseInt(kioskId)))
				.setColumns(["id", "location_id"]),
		),
	);
	// Create product
	const product = useMemo(() => {
		if (!products) return;
		return products[0];
	}, [products]);
	// Create kiosk
	const kiosk = useMemo(() => {
		if (!kiosks) return;
		return kiosks[0];
	}, [kiosks]);
	const priceInDollars = useMemo(() => {
		const price = product?.prices?.[0]?.metadata.Tiers[0].AmountPerUnit;
		if (price) {
			return "$" + price / 100;
		}
		return undefined;
	}, [product]);
	// Show loading
	if (!accountApproach || !kiosk || !productId || !initialCustomerPhoneNumber) {
		return <IsLoading />;
	}
	// Return context provider
	return (
		<MembershipPurchaseContextProvider
			value={{
				loading: loadingAccountApproach || loadingProduct || loadingKiosk,
				accountApproach,
				kiosk: kiosk,
				initialCustomerPhoneNumber,
				product,
				userData,
				setUserData,
				screen,
				setScreen,
				priceInDollars,
			}}
		>
			{children}
		</MembershipPurchaseContextProvider>
	);
};
