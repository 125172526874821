import { EEndpointType, IGetNonsubscribedUserByPhoneNumberResponse } from "amp";
import { useApi } from "src/hooks/useApi";

export const useGetNonsubscribedUserByPhoneNumber = () => {
	const { get } = useApi(EEndpointType.Anonymous, "user");

	const getNonsubscribedUserByPhoneNumber = async (phoneNumber: string) => {
		return get<IGetNonsubscribedUserByPhoneNumberResponse | undefined>(
			`get-non-subscribed-user-by-phone-number/${phoneNumber}`,
		);
	};

	return getNonsubscribedUserByPhoneNumber;
};
