import { useCallback } from "react";
import axios from "../utils/axios";
import { FetchData, FetchDataConfig, useFetchData } from "./useFetchData";
import { DynamicSql, EEndpointType, XDynamicSqlData, XDynamicSqlName } from "amp";

const _COUNT = Symbol("Count");

export function useCountDataDynamic<TDynamicSqlName extends XDynamicSqlName>(
	query: DynamicSql<TDynamicSqlName>,
	config?: FetchDataConfig,
): FetchData<number> {
	// Create get data
	const getData = useCallback(
		async () => _fetchDataDynamic(query, _COUNT),
		[query],
	);
	// Use data
	return useFetchData(getData, config);
}

export function useFetchDataDynamic<TDynamicSqlName extends XDynamicSqlName>(
	query: DynamicSql<TDynamicSqlName>,
	config?: FetchDataConfig,
): FetchData<XDynamicSqlData<TDynamicSqlName>[]> {
	// Create get data
	const getData = useCallback(async () => _fetchDataDynamic(query), [query]);
	// Use data
	return useFetchData(getData, config);
}

export async function fetchDataDynamic<TDynamicSqlName extends XDynamicSqlName>(
	query: DynamicSql<TDynamicSqlName>,
): Promise<XDynamicSqlData<TDynamicSqlName>[]> {
	// Return data
	return _fetchDataDynamic(query);
}

export async function countDataDynamic<TDynamicSqlName extends XDynamicSqlName>(
	query: DynamicSql<TDynamicSqlName>,
): Promise<number> {
	// Return data
	return _fetchDataDynamic(query, _COUNT);
}

async function _fetchDataDynamic<TDynamicSqlName extends XDynamicSqlName>(
	dynamic: DynamicSql<TDynamicSqlName>,
): Promise<XDynamicSqlData<TDynamicSqlName>[]>;
async function _fetchDataDynamic<TDynamicSqlName extends XDynamicSqlName>(
	dynamic: DynamicSql<TDynamicSqlName>,
	count: typeof _COUNT,
): Promise<number>;
async function _fetchDataDynamic<TDynamicSqlName extends XDynamicSqlName>(
	dynamic: DynamicSql<TDynamicSqlName>,
	count?: typeof _COUNT,
): Promise<XDynamicSqlData<TDynamicSqlName>[] | number> {
	// Get name
	const name = dynamic.getName();
	// Get query
	const query = dynamic.buildQuery();
	// Get data
	const { data } = await axios.post<
		XDynamicSqlData<TDynamicSqlName>[] | number
	>(
		`/${EEndpointType.Anonymous}/dynamic/query/${name}`,
		count === _COUNT ? { ...query, count: true } : query,
	);
	// Return data
	return data;
}
