const environment = {
	api: {
		url: "https://api-devops.ampmemberships.com",
	},
	localhost: {
		tenantKey: process.env.LOCALHOST_TENANTKEY??"",
	},
	aws: {
		url: "https://devops-ampmemberships-com.s3.us-west-1.amazonaws.com/public",
	},
	appInsights: {
		instrumentationKey: "0df266a9-6db2-423f-9fc7-611cd393c9dc" ,
	},
	sentry: {
		dsn: "https://2d20c4baab7544c3b5dc49347770b852@o4505036277481472.ingest.sentry.io/4505036297732096",
	},
	mapbox: {
		apiKey: "pk.eyJ1IjoiaW5zaXRlbnhhbXAiLCJhIjoiY2w2NnZpYXB6MDM0bjNjcno2cjJ2a2o5ZyJ9.FhL2-3SmRc25dEQD7Qc4_Q" ,
	},
};

export default environment;
