import { AmpText } from "../typography/AmpText";
import { IAmpCheckboxProps } from "./types/AmpCheckboxTypes";
import { FormControlLabel, Checkbox } from "@material-ui/core";

export const AmpCheckbox = ({ label, ...props }: IAmpCheckboxProps) => {
	return (
		<FormControlLabel
			control={<Checkbox {...props} />}
			label={
				<AmpText type="body_sm" color="primary" fontWeight="bold">
					{label}
				</AmpText>
			}
		/>
	);
};
