import { useEffect, useCallback } from "react";
import { useEmailUnsubscribeEndpoints } from "./useEmailUnsubscribeEndpoints";
import { IEmailUnsubscribeRequest } from "amp";
import { isTopicValid } from "../utils/validate-topic";
import { useStateWithMounted } from "../../../hooks/useStateWithMounted";

export const useUpdateNotificationPreferences = ({
	id,
	topic,
}: IEmailUnsubscribeRequest) => {
	const { updateNotificationPreferences } = useEmailUnsubscribeEndpoints();
	const [loading, setLoading] = useStateWithMounted(true);
	const [success, setSuccess] = useStateWithMounted<boolean | undefined>();

	const handleUpdate = useCallback(async () => {
		const response = await updateNotificationPreferences({
			id,
			topic,
		});
		setSuccess(response.success);
		setLoading(false);
	}, [
		id,
		topic,
		updateNotificationPreferences,
	]);

	useEffect(() => {
		if (
			id
			&& topic
			&& isTopicValid(topic)
		) {
			handleUpdate();
		} else {
			setSuccess(false);
			setLoading(false);
		}
	}, []);

	return {
		loading,
		success,
	};
};
