import React, { FC, PropsWithChildren, useCallback, useState } from "react";
import { initializeContext } from "../utils/initialize-context";
import { IModalProps, AmpModal } from "../components/modal/AmpModal";
import { ErrorModal } from "../components/modal/ErrorModal";

interface IModalContext {
	close: () => void;
	customModal: (props: XCustomModalProps) => void;
	showError: (props: XShowErrorModalProps) => void;
}

type XShowErrorModalProps = Pick<XErrorModalState, "headerText" | "message">;

enum EModalType {
	none,
	error,
	custom,
}

type XCustomModalProps = Pick<IModalProps,"onClose"> & { modalContent: React.ReactNode};

type XErrorModalState = {
	type: EModalType.error;
	message: string;
	headerText: string;	
}
;
type XClosedModalState = { type: EModalType.none };

type XCustomModalState = { type: EModalType.custom } & XCustomModalProps;

type XModalState = XClosedModalState | XCustomModalState | XErrorModalState;

export const [ModalContext, useModalContext, ModalContextProvider] =
	initializeContext<IModalContext>("Branding");

export const ModalProvider: FC<PropsWithChildren<IModalContext>> = ({
	children,
}): JSX.Element => {
	const [state, setState] = useState<XModalState>({
		type: EModalType.none,
	});
	const close = () => setState({ type: EModalType.none });
	const showError = useCallback(
		({ headerText, message}: Pick<XErrorModalState, "headerText" | "message">
		) => {
			setState({
				type: EModalType.error,
				message,
				headerText,
			});
		}, []);
	const customModal = ({ modalContent, onClose }: XCustomModalProps) => {
		const customClose = () => {
			close();
			onClose();
		};
		setState({
			type: EModalType.custom,
			modalContent,
			onClose: customClose,
		});
	};

	return (
		<ModalContextProvider value={{ close, customModal, showError }}>
			{children}
			<AmpModal
				visible={state.type === EModalType.custom}
				onClose={(state as XCustomModalState).onClose}
			>
				{(state as XCustomModalState).modalContent}
			</AmpModal>

			<ErrorModal
				visible={state.type === EModalType.error}
				message={(state as XErrorModalState).message}
				headerText={(state as XErrorModalState).headerText}
				onDismiss={close}
			/>
		</ModalContextProvider>
	);
};
