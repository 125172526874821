
import { EEndpointType, IDynamicSqlQuery, XDynamicSqlData, XDynamicSqlName } from "amp";
import axios from "../utils/axios";
import { useCallback, useMemo } from "react";

export const useDynamicApi = () => {
	const { post } = useApi(EEndpointType.Anonymous, "dynamic/query");

	const dynamic = useCallback(
		<TFetchDataDynamicName extends XDynamicSqlName>(
			name: TFetchDataDynamicName,
			query?: IDynamicSqlQuery,
		): Promise<XDynamicSqlData<TFetchDataDynamicName>[]> =>
			post<IDynamicSqlQuery, XDynamicSqlData<TFetchDataDynamicName>[]>(
				name,
				query ?? {},
			),
		[post],
	);

	return { dynamic };
};

export const useApi = (type: EEndpointType, baseRoute: string) => {
	return useMemo(() => {
		const get = <TReturn>(route: string): Promise<TReturn> =>
			axios.get<TReturn>(`${type}/${baseRoute}/${route}`).then((x) => x.data);

		const post = <TBody, TReturn>(
			route: string,
			body: TBody | undefined,
		): Promise<TReturn> =>
				axios.post(`${type}/${baseRoute}/${route}`, body).then((x) => x.data);

		return { post, get };
	}, [baseRoute, type]);
};


export const usePrepaidWashApi = () => useApi(EEndpointType.Anonymous, "prepaid-wash-promotions");