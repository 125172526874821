import React, { FC, PropsWithChildren } from "react";
import { PrepaidWashesProviders } from "./prepaid-washes-providers";
import { createLayout } from "../../../utils/layout";
import { usePrepaidWashesContext } from "../contexts/PrepaidWashPromotionsContext";
import { LoadingScreen } from "../../../screens/loading/LoadingScreen";
import { PrepaidWashesHeader } from "../shared-components/header/PrepaidWashesHeader";
import { Container } from "@material-ui/core";

export const PrepaidWashesLayout = createLayout(({ children }) => {
	return (
		<PrepaidWashesProviders>
			<PrepaidWashesApp>{children}</PrepaidWashesApp>
		</PrepaidWashesProviders>
	);
});

export const PrepaidWashesApp: FC<PropsWithChildren> = ({
	children,
}): JSX.Element => {
	const { loading } = usePrepaidWashesContext();
	if (loading) {
		return <LoadingScreen />;
	}
	return (
		<>
			<PrepaidWashesHeader />
			<Container>
				<>{children}</>
			</Container>
		</>
	);
};
