import React, { useMemo, useCallback } from "react";
import { usePrepaidWashesContext } from "../../contexts/PrepaidWashPromotionsContext";
import style from "./prepaid-washes-header.module.scss";
import { Grid, Typography } from "@material-ui/core";
import { Header } from "../../../../components/ui-elements/header/Header";

export const PrepaidWashesHeader = () => {
	const { configuration } = usePrepaidWashesContext();

	const {
		enable_header,
		header_title,
		header_background_color,
		header_text_color,
		header_hyperlink_url,
		header_logo_url,
	} = configuration ?? {};

	const clickAction = useCallback(() => {
		if (header_hyperlink_url) {
			window.open(header_hyperlink_url, "_blank");
		}
	}, [header_hyperlink_url]);
	
	const left = useMemo(() => {
		return header_logo_url ? (
			<Grid container style={{ paddingLeft: 25 }}>
				<img
					src={header_logo_url}
					alt="Image of company logo"
					width="100%"
					height={65}
					style={{objectFit: "contain"}}
					onClick={() => (clickAction ? clickAction() : undefined)}
					className={header_hyperlink_url ? style.headerImage : undefined}
				/>

			</Grid>
		) :  null;
	}, [header_logo_url, clickAction, header_hyperlink_url]);

	const center = useMemo(() => {
		return (
			<Grid container justifyContent="center">
				<Typography variant="h4"
					style={{ fontWeight: "bold", textAlign: "center"}}>
					{header_title}
				</Typography>
			</Grid>
		);
	}, [header_title]);

	return enable_header ? (
		<Header
			backgroundColor={header_background_color}
			left={left}
			center={center}
			textColor={header_text_color}
		/>
	) : null;
};
