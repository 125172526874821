import React, { FC, PropsWithChildren } from "react";
import { LoadingScreen } from "../../screens/loading/LoadingScreen";

interface INonBlockingLoadingProps {
	loading: boolean;
	loadingText?: string;
}
export const NonBlockingLoading: FC<PropsWithChildren<INonBlockingLoadingProps>> = ({
	loading,
	loadingText,
	children,
}) => (
	<>
		{loading && (
			<div style={{ height: "100%", justifyContent: "center" }}>
				<LoadingScreen
					loadingText={loadingText}
				/>
			</div>
		)}
		<div
			style={{ display: loading ? "none" : "block" }}
		>
			{children}
		</div>
	</>
);
