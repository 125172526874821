import React from "react";
import { LinearProgress } from "@material-ui/core";
import styles from "./loading.module.scss";

export const LoadingScreen = ({ loadingText }: { loadingText?: string }) => {
	const { loadingScreen } = styles;
	return (
		<div className={loadingScreen}>
			<div style={{ width: "40%" }}>
				<LinearProgress />
				{loadingText ? <div>{loadingText}</div> : null}
			</div>
		</div>
	);
};
