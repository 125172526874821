import React, { FC } from "react";

/**
 * Lets you nest components in a clean way
 * and allows for children to pass through.
 *
 * @example
 *
 * // This -->
 * const AppProvider = nestComponents(One, Two, Three)
 *
 * // Instead of this -->
 * const AppProvider = ({children}) => (
 * 	<One>
 * 		<Two>
 * 			<Three>{children}</Three>
 * 		</Two>
 * 	</One>
 * )
 */
export const combineComponents = (...components: FC<any>[]): FC<any> =>
	components.reduce(
		(Accumulated, Component): FC<any> =>
			// eslint-disable-next-line react/display-name
			({ children }) =>
				(
					<Accumulated>
						<Component>{children}</Component>
					</Accumulated>
				),
		({ children }) => <>{children}</>,
	);
