import axios from "axios";
import environment from "../environment";

const axiosInstance = axios.create({
	baseURL: environment.api.url,
});

axiosInstance.interceptors.request.use(
	(request) => request,
	(error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
	(response) => response,
	(error) => Promise.reject(error),
);

export default axiosInstance;
