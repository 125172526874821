import { XAmpCardProps } from "./types/AmpCardTypes";
import styles from "./css/AmpCard.module.scss";
import { getSpacing } from "../utils/get-spacing";
import { Card } from "@material-ui/core";

export const AmpCard = ({
	children,
	onClick,
	flexDirection = "column",
	fullWidth,
	fullHeight,
	...props
}: XAmpCardProps) => {
	const getClassName = () => {
		const classes = [styles["amp-card"]];

		if (flexDirection) {
			classes.push(styles[`direction-${flexDirection}`]);
		}
		if (props.alignItems) {
			classes.push(styles[`align-${props.alignItems}`]);
		}
		if (props.justifyContent) {
			classes.push(styles[`justify-${props.justifyContent}`]);
		}

		return classes.filter(Boolean).join(" ");
	};

	// Handle numeric values through inline styles
	const style = {
		padding: getSpacing(props.padding) ?? "16px",
		gap: getSpacing(props.gap),
		width: fullWidth ? "100%" : undefined,
		height: fullHeight ? "100%" : undefined
	};

	return (
		<Card className={getClassName()} style={style} onClick={onClick}>
			{children}
		</Card>
	);
};
