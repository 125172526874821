import { PauseLoading } from "../components/loading/PauseLoading";
import { combineComponents } from "../utils/combineComponents";
import { ApiProvider } from "./ApiContext";
import { AppInsightsProvider } from "./AppInsights";
import { ThemeContextProvider } from "./AppThemeContext";
import { BrandingProvider } from "./BrandingContext";
import { LoadingProvider } from "./LoadingContext";
import { ModalProvider } from "./ModalContext";
import { StripeProvider } from "./StripeContext";
import { TenantProvider } from "./TenantContext";

export const GlobalAppProvider = combineComponents(
	AppInsightsProvider,
	LoadingProvider,
	TenantProvider,
	ApiProvider,
	BrandingProvider,
	ThemeContextProvider,
	StripeProvider,
	ModalProvider,
	PauseLoading
);