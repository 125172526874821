import styles from "./css/AmpText.module.scss";
import { IAmpTextStyleProps } from "./types/AmpTextTypes";

export const AmpText = ({
	children,
	fontWeight = "normal",
	...props
}: IAmpTextStyleProps) => {
	const getClassName = () => {
		const classes = [styles["amp-text"]];

		if (props.type) classes.push(styles[`size-${props.type}`]);
		if (fontWeight) classes.push(styles[`weight-${fontWeight}`]);
		if (props.color) classes.push(styles[`color-${props.color}`]);
		if (props.align) classes.push(styles[`align-${props.align}`]);
		if (props.textTransform)
			classes.push(styles[`transform-${props.textTransform}`]);

		return classes.filter(Boolean).join(" ");
	};

	const getComponent = () => {
		switch (props.type) {
			case "h6":
				return "h6";
			case "h5":
				return "h5";
			case "h4":
				return "h4";
			case "h3":
				return "h3";
			case "h2":
				return "h2";
			case "h1":
				return "h1";
			default:
				return "p";
		}
	};

	const Component = getComponent();

	return <Component className={getClassName()}>{children}</Component>;
};
