import {
	EEndpointType,
	IGetLicensePlateDataFromSessionAtKioskRequest,
	IGetLicensePlateDataFromSessionAtKioskResponse,
} from "amp";
import { useApi } from "../../../hooks/useApi";
import { useFetchData } from "../../../hooks/useFetchData";

export const useGetLicensePlateDataFromKioskSession = (kioskId: number) => {
	const { post } = useApi(EEndpointType.Anonymous, "tunnel-session");
	const { data: licensePlateData, loading } = useFetchData(async () => {
		return post<
			IGetLicensePlateDataFromSessionAtKioskRequest,
			IGetLicensePlateDataFromSessionAtKioskResponse
		>("get-license-plate-data-from-session-at-kiosk", { kioskId });
	});
	return { licensePlateData, loading };
};
