import React, {
	FC,
	PropsWithChildren,
	useEffect,
	useRef,
	useState,
} from "react";
import { IsLoading } from "../components/loading/IsLoading";
import axios from "../utils/axios";
import { initializeContext } from "../utils/initialize-context";
import { useTenantContext } from "./TenantContext";
import { InternalAxiosRequestConfig } from "axios";

export const [ApiContext, useApiContext, ApiContextProvider] =
	initializeContext("Api");

export const ApiProvider: FC<PropsWithChildren> = ({
	children,
}): JSX.Element => {
	const { tenantKey } = useTenantContext();
	const [tokenInterceptorId, setTokenInterceptorId] = useState<
		number | undefined
	>(undefined);
	const tokenInterceptorIdNewRef = useRef<number>();
	useEffect(() => {
		if (tokenInterceptorId !== undefined) {
			axios.interceptors.request.eject(tokenInterceptorId);
		}
		tokenInterceptorIdNewRef.current = axios.interceptors.request.use(
			(request: InternalAxiosRequestConfig) => {
				// Make sure we modify the headers in a type-safe way
				if (request.headers) {
					request.headers["Amp-Tenant"] = tenantKey;
				}
				return request; // Return the modified request object directly
			},
		);
		setTokenInterceptorId(tokenInterceptorIdNewRef.current);
	}, [tenantKey]);

	if (
		!tokenInterceptorId ||
		tokenInterceptorIdNewRef.current !== tokenInterceptorId
	) {
		return <IsLoading />;
	}
	return <ApiContextProvider value={{}}>{children}</ApiContextProvider>;
};
