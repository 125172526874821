import { CircularProgress } from "@material-ui/core";
import { IAmpLoadingSpinnerProps } from "./types/AmpLoadingSpinnerTypes";
import styles from "./css/AmpLoadingSpinner.module.scss";

export const AmpLoadingSpinner = ({
	size = "md",
	color = "primary",
}: IAmpLoadingSpinnerProps) => {
	const getClassName = () => {
		const classes = [
			styles["amp-spinner"],
			styles[`size-${size}`],
			styles[`color-${color}`],
		];

		return classes.filter(Boolean).join(" ");
	};

	return <CircularProgress className={getClassName()} />;
};
