import { DynamicSql } from "amp";
import { useFetchDataDynamic } from "src/hooks/useFetchDataDynamic";

export const useGetReferralCodeDiscount = () => {
    const { data: generalInformation, loading } = useFetchDataDynamic(
		new DynamicSql("generalInformation").setColumns([
			"referral_discount__c",
		]),
	);
    const referralDiscount = generalInformation?.[0]?.referral_discount__c;
	return {
		referralDiscount,
		loading,
	};
};