import { useCallback, useEffect, useMemo, useReducer, useRef } from "react";
import useIsMountedRef from "./useIsMountedRef";

export type FetchDataConfig = {
	handleInitialDataLoad: boolean;
};

export type FetchData<T> = {
	data: T | null;
	loading: boolean;
	isError: boolean;
	setData: (data: T | null) => void;
	retry: () => Promise<boolean>;
};

type FetchDataState<T> = {
	data: T | null;
	loading: boolean;
	isError: boolean;
};

export function useFetchData<T>(
	getData: () => Promise<T>,
	config?: FetchDataConfig,
): FetchData<T> {
	const handleInitialDataLoad = config?.handleInitialDataLoad ?? true;

	const isMountedRef = useIsMountedRef();
	const isInitializedRef = useRef(false);
	const tryNumberRef = useRef(0);

	const [state, mergeState] = useReducer<
		(
			stateCurrent: FetchDataState<T>,
			stateUpdate: Partial<FetchDataState<T>>,
		) => FetchDataState<T>
			>((stateCurrent, stateUpdate) => ({ ...stateCurrent, ...stateUpdate }), {
				data: null,
				loading: handleInitialDataLoad,
				isError: false,
			});

	const fetchAndSetData = useCallback(async (): Promise<boolean> => {
		tryNumberRef.current++;
		const tryNumber = tryNumberRef.current;

		// Check if the component is still mounted and that another request has not been fired
		const getShouldSet = () =>
			isMountedRef.current && tryNumber === tryNumberRef.current;


		let dataWasSet = false;

		try {
			mergeState({ loading: true });
			const data = await getData();
			mergeState({
				data,
				loading: false,
				isError: false,
			});
			dataWasSet = true;
		} catch (err) {
			console.error(err);

			if (getShouldSet()) {
				mergeState({
					isError: true,
					loading: false,
				});
				dataWasSet = true;
			}
		}

		return dataWasSet;
	}, [isMountedRef, tryNumberRef, getData]);

	useEffect(() => {
		if (handleInitialDataLoad && !isInitializedRef.current) {
			isInitializedRef.current = true;
			fetchAndSetData();
		}
	}, [isInitializedRef, fetchAndSetData]);

	return useMemo(() => {
		const { data, loading, isError } = state;
		const setData = (dataNew: T | null) => mergeState({ data: dataNew });
		const retry = fetchAndSetData;
		return { data, loading, isError, setData, retry };
	}, [state, fetchAndSetData]);
}
