import { AmpButton } from "src/components/ui-core/buttons/AmpButton";
import { AmpCard } from "src/components/ui-core/containers/AmpCard";
import { AmpContainer } from "src/components/ui-core/containers/AmpContainer";
import { AmpDivider } from "src/components/ui-core/spacers/AmpDivider";
import { AmpSuccessCheckmark } from "src/components/ui-core/svgs/AmpSuccessCheckmark";
import { AmpText } from "src/components/ui-core/typography/AmpText";
import { Grid } from "@material-ui/core";

export const PaymentSuccessScreen = () => {
	return (
		<AmpContainer
			padding={4}
			alignItems="center"
			width="full"
			flexGrow={1}
			color="surface"
		>
			<Grid
				container
				item
				direction="column"
				md={6}
				sm={12}
				justifyContent="center"
				style={{ height: "100%" }}
			>
				<AmpCard padding={6} fullWidth alignItems="center" gap={6} fullHeight>
					<AmpContainer
						flex={1}
						alignItems="center"
						width="full"
						justifyContent="center"
						gap={4}
					>
						<AmpSuccessCheckmark />
						<AmpContainer
							width="full"
							alignItems="center"
							justifyContent="center"
						>
							<AmpText type="body_lg" fontWeight="bold" align="center">
								Congratulations!
							</AmpText>
							<AmpText type="body_sm" color="gray" align="center">
								Please proceed at the kiosk
							</AmpText>
						</AmpContainer>
					</AmpContainer>
					<AmpDivider />
					<AmpContainer
						flex={1}
						alignItems="center"
						width="full"
						justifyContent="center"
						gap={10}
					>
						<AmpContainer
							gap={2}
							width="full"
							alignItems="center"
							justifyContent="center"
						>
							<AmpText type="body_lg" fontWeight="bold" align="center">
								Haven't Downloaded the App Yet?
							</AmpText>
							<AmpText
								type="body_sm"
								color="gray"
								align="center"
							>
								You can download the mobile app by clicking the button below:
							</AmpText>
						</AmpContainer>
						<AmpButton
							label="Download App"
							onClick={() => {}}
							colorType="secondary"
						/>
					</AmpContainer>
				</AmpCard>
			</Grid>
		</AmpContainer>
	);
};
