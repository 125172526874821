import React from "react";
import * as Sentry from "@sentry/react";
import { GlobalAppProvider } from "./contexts/GlobalAppProvider";
import { Routes, BrowserRouter } from "react-router-dom";
import { renderRoutes, routes } from "./routes";
import environment from "./environment";
import ErrorBoundary from "./components/ErrorBoundary";

function App() {
	const env = process.env.NODE_ENV;
	const dsn = environment.sentry.dsn;

	if (env !== "development") {
		Sentry.init({
			dsn,
			environment: env,
		});
	}

	return (
		<BrowserRouter>
			<ErrorBoundary>
				<GlobalAppProvider>
					<Routes>{renderRoutes(routes)}</Routes>
				</GlobalAppProvider>
			</ErrorBoundary>
		</BrowserRouter>
	);
}

export default App;
