import React, { FC, PropsWithChildren } from "react";
import { ThemeProvider } from "@material-ui/core";
import { useCreateTheme } from "../styles/theme";
import { useBrandingContext } from "./BrandingContext";
import { ThemeVariables } from "src/themes/ThemeVariables";

export const ThemeContextProvider: FC<PropsWithChildren<unknown>> = ({
	children,
}): JSX.Element => {
	const { theme } = useBrandingContext();
	const _theme = useCreateTheme({
		direction: "ltr",
		responsiveFontSizes: true,
		theme: "LIGHT",
		primaryColor: theme.primary_color,
		secondaryColor: theme.secondary_color,
	});

	return (
		<ThemeProvider theme={_theme}>
			<ThemeVariables />
			{children}
		</ThemeProvider>
	);
};

export * from "../styles/theme";
