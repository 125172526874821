import { FC, PropsWithChildren, useState } from "react";
import { LoadingScreen } from "../../../screens/loading/LoadingScreen";
import { createLayout } from "../../../utils/layout";
import {
	MembershipPurchaseProvider,
	useMembershipPurchaseContext,
} from "../contexts/MembershipPurchaseContext";
import AmpHead from "src/components/amp-head/AmpHead";
import MembershipPurchase from "..";
import { AmpContainer } from "src/components/ui-core/containers/AmpContainer";
import { AmpLogoHeader } from "src/components/ui-core/headers/AmpLogoHeader";

export const MembershipPurchaseLayout = createLayout(({ children }) => {
	return (
		<MembershipPurchaseProvider>
			<MembershipPurchaseApp />
		</MembershipPurchaseProvider>
	);
});

export const MembershipPurchaseApp = () => {
	const { loading } = useMembershipPurchaseContext();
	if (loading) {
		return <LoadingScreen />;
	}
	return (
		<>
			<AmpContainer width="screen" height="screen">
				<AmpHead />
				<AmpLogoHeader />
				<MembershipPurchase />
			</AmpContainer>
		</>
	);
};
