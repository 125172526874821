import React from "react";
import styles from "./header.module.scss";
import { XOptional } from "amp";
import { Grid } from "@material-ui/core";

interface IHeaderProps {
	left?: React.ReactNode;
	right?: React.ReactNode;
	center?: React.ReactNode;
	backgroundColor: XOptional<string>;
	textColor: XOptional<string>;
}

export const Header = ({
	left,
	center,
	right,
	backgroundColor,
	textColor,
}: IHeaderProps) => {
	const {
		header,
	} = styles;
	return (
		<Grid container 
			justifyContent="space-between" 
			alignItems="center"
			className={header}
			style={{
				boxShadow: "0px 4px 10px 2px #aaa",
				backgroundColor: backgroundColor ?? undefined,
				color: textColor ?? undefined,
			}}>
			<Grid xs={4} item>
				{left}
			</Grid>
			<Grid xs={4} item>
				{center}
			</Grid>
			<Grid xs={4} item>
				{right}
			</Grid>
		</Grid>
	);
};
