import React, { FC, PropsWithChildren } from "react";
import { EmailUnsubscribeProviders } from "./email-unsubscribe-providers";
import { createLayout } from "../../../utils/layout";
import { useEmailUnsubscribeContext } from "../contexts/EmailUnsubscribeContext";
import { LoadingScreen } from "../../../screens/loading/LoadingScreen";
import { Container } from "@material-ui/core";

export const EmailUnsubscribeLayout = createLayout(({ children }) => {
	return (
		<EmailUnsubscribeProviders>
			<EmailUnsubscribeApp>{children}</EmailUnsubscribeApp>
		</EmailUnsubscribeProviders>
	);
});


const EmailUnsubscribeApp: FC<PropsWithChildren> = ({
	children,
}): JSX.Element => {
	const { loading } = useEmailUnsubscribeContext();

	if (loading) {
		return <LoadingScreen />;
	}
	return (
		<Container style={{ marginLeft: 0 }}>
			<>
				{children}
			</>
		</Container>
	);
};
