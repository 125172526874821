import { useBrandingContext } from "src/contexts/BrandingContext";
import { AmpContainer } from "../containers/AmpContainer";
import {} from "@material-ui/core";
import { AmpDivider } from "../spacers/AmpDivider";

export const AmpLogoHeader = () => {
	const { images } = useBrandingContext();
	const image = images?.BrandLogo?.image_url__c;
	return (
		<AmpContainer width="full">
			<AmpContainer
				width="full"
				alignItems="center"
				justifyContent="center"
				padding={4}
			>
				<img src={image} alt="logo" style={{ height: 30 }} />
			</AmpContainer>
			<AmpDivider />
		</AmpContainer>
	);
};
