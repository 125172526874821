import { Button } from "@material-ui/core";
import {
	IAmpButtonProps,
	XAmpButtonSize,
	XButtonColorType,
	XButtonType,
} from "./types/AmpButtonTypes";
import styles from "./css/AmpButton.module.scss";
import { AmpContainer } from "../containers/AmpContainer";
import { AmpText } from "../typography/AmpText";
import { XFontSize } from "../typography/types/AmpTextTypes";
import { AmpSpacer } from "../spacers/AmpSpacer";
import { XLoadingSpinnerColorType } from "../loaders/types/AmpLoadingSpinnerTypes";
import { AmpLoadingSpinner } from "../loaders/AmpLoadingSpinner";

export const AmpButton = ({
	disabled = false,
	label,
	loading,
	colorType = "primary",
	width = "normal",
	type = "filled",
	size = "md",
	...props
}: IAmpButtonProps) => {
	const getClassName = () => {
		const classes = [
			styles["amp-button"],
			styles[`width-${width}`],
			styles[`color-${colorType}-${type}`],
			styles[`size-${size}`],
		];

		return classes.filter(Boolean).join(" ");
	};

	const getAmpTextType = (size: XAmpButtonSize): XFontSize => {
		switch (size) {
			case "xxl":
				return "button_xxl";
			case "xl":
				return "button_xl";
			case "lg":
				return "button_lg";
			case "md":
				return "button_md";
			case "sm":
				return "button_sm";
			default:
				return "button_md";
		}
	};

	const getLoadingSpinnerColor = (
		colorType: XButtonColorType,
		type: XButtonType,
	): XLoadingSpinnerColorType => {
		switch (type) {
			case "filled":
				return "white";
			case "outlined":
				return colorType;
			case "text":
				return colorType;
			default:
				return "primary";
		}
	};

	return (
		<Button
			{...props}
			disabled={disabled}
			className={getClassName()}
			classes={{
				label: styles["amp-button-label"],
			}}
		>
			<AmpContainer
				alignItems="center"
				justifyContent="center"
				gap={2}
				direction="row"
			>
				{loading && <AmpSpacer width={4} />}
				<AmpText type={getAmpTextType(size)} fontWeight="bold">
					{label}
				</AmpText>
				{loading && (
					<AmpLoadingSpinner
						size="sm"
						color={getLoadingSpinnerColor(colorType, type)}
					/>
				)}
			</AmpContainer>
		</Button>
	);
};
