import React, { FC, PropsWithChildren, useState } from "react";
import { NonBlockingLoading } from "../components/loading/NonBlockingLoading";
import { XSetStateAction } from "../types/set-state-action.type";
import { initializeContext } from "../utils/initialize-context";

interface ITenantContext {
	loading: boolean;
	setLoadingText: XSetStateAction<string | undefined>;
	setLoading: XSetStateAction<boolean>;
}

export const [LoadingContext, useLoadingContext, LoadingContextProvider] =
	initializeContext<ITenantContext>("Loading");

export const LoadingProvider: FC<PropsWithChildren<ITenantContext>> = ({
	children,
}): JSX.Element => {
	const [loading, setLoading] = useState<boolean>(true);
	const [loadingText, setLoadingText] = useState<string | undefined>();

	return (
		<LoadingContextProvider value={{ loading, setLoadingText, setLoading }}>
			<NonBlockingLoading
				loading={loading}
				loadingText={loadingText}
			>
				{children}
			</NonBlockingLoading>
		</LoadingContextProvider>
	);
};