import { Button, CircularProgress } from "@material-ui/core";
import React, { useRef } from "react";

interface IButtonProps {
	variant?: "outlined" | "contained" | "text";
	color?: "primary" | "secondary" | "default" | "inherit";
	buttonText: string;
	size?: "large" | "medium" | "small";
	onClick?: () => void;
	styles?: React.CSSProperties;
	fullWidth?: boolean;
	submit?: boolean;
	disabled?: boolean;
	loading?: boolean;
	className?: string;
}
/*
 * @deprecated Use AmpButton instead
 */
export const AmpButtonOld = ({
	variant = "outlined",
	color = "primary",
	fullWidth = true,
	styles,
	buttonText,
	onClick,
	size = "large",
	submit = false,
	loading = false,
	disabled = false,
	className,
}: IButtonProps) => {
	const buttonRef = useRef(null);
	return (
		<Button
			ref={buttonRef}
			className={className}
			fullWidth={fullWidth}
			color={color}
			variant={variant}
			disabled={disabled}
			size={size}
			onClick={onClick}
			style={styles}
			type={submit ? "submit" : "button"}
		>
			{buttonText}
			{loading && <CircularProgress size={20} style={{ marginLeft: 10 }} />}
		</Button>
	);
};
