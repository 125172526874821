import { EMessageTopic } from "amp";

export const isTopicValid = (value: string) => {
    return value === EMessageTopic.Account || value === EMessageTopic.Marketing;
}

export const getTopicString = (value: string) => {
    const isValidTopic = isTopicValid(value);
    if (isValidTopic) {
        switch (value) {
            case EMessageTopic.Account:
                return "account update";
            case EMessageTopic.Marketing:
                return "promotional";
            default:
                return "our";
        }
    } else {
        return "our";
    }
}