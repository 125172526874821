import { FC, PropsWithChildren, useEffect, useRef, useState } from "react";
import { Sheet } from "react-modal-sheet";

export interface IAmpBottomSheetProps {
  isOpen: boolean;
  onClose: () => void;
}

const isIOS = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
};

const useVisualViewport = () => {
  const [viewport, setViewport] = useState({
    height: window.innerHeight,
    offsetTop: 0,
    isKeyboardOpen: false
  });

  useEffect(() => {
    // Only set up viewport tracking on iOS
    if (!isIOS()) {
      return;
    }

    const handleResize = () => {
      if (window.visualViewport) {
        const isKeyboardVisible = window.innerHeight - window.visualViewport.height > 100;
        setViewport({
          height: window.visualViewport.height,
          offsetTop: window.visualViewport.offsetTop,
          isKeyboardOpen: isKeyboardVisible
        });
      }
    };

    // Initial setup
    handleResize();

    // Add event listeners
    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', handleResize);
      window.visualViewport.addEventListener('scroll', handleResize);
    }

    // Cleanup
    return () => {
      if (window.visualViewport) {
        window.visualViewport.removeEventListener('resize', handleResize);
        window.visualViewport.removeEventListener('scroll', handleResize);
      }
    };
  }, []);

  return viewport;
};

export const AmpBottomSheet: FC<PropsWithChildren<IAmpBottomSheetProps>> = ({
  isOpen,
  onClose,
  children
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const viewport = useVisualViewport();
  const isiOSDevice = isIOS();

  const contentStyle: React.CSSProperties = isiOSDevice ? {
    position: 'relative',
    minHeight: '100px',
    maxHeight: viewport.isKeyboardOpen ? `${viewport.height}px` : undefined,
    overflowY: 'auto',
    transition: 'padding-bottom 0.3s ease',
    paddingBottom: viewport.isKeyboardOpen ? (window.innerHeight - viewport.height) : 0
  } : {};

  const sheetStyle: React.CSSProperties = (isiOSDevice && viewport.isKeyboardOpen) ? {
    transform: `translateY(-${viewport.offsetTop}px)`,
    transition: 'transform 0.3s ease'
  } : {};

  return (
    <Sheet 
      isOpen={isOpen} 
      onClose={onClose} 
      detent="content-height"
      style={sheetStyle}
    >
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>
          <div ref={contentRef} style={contentStyle}>
            {children}
          </div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={onClose} />
    </Sheet>
  );
};