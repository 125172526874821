import {
	PaymentElement,
	useElements,
	useStripe,
} from "@stripe/react-stripe-js";
import { StripePaymentElementChangeEvent } from "@stripe/stripe-js";
import { EAccountApproach } from "amp";
import { useCallback, useState } from "react";
import { AmpBottomSheet } from "src/components/amp-bottom-sheet/AmpBottomSheet";
import { AmpButton } from "src/components/ui-core/buttons/AmpButton";
import { AmpContainer } from "src/components/ui-core/containers/AmpContainer";
import { AmpSpacer } from "src/components/ui-core/spacers/AmpSpacer";
import { useModalContext } from "src/contexts/ModalContext";
import {
	EMembershipPurchaseScreen,
	useMembershipPurchaseContext,
} from "src/sites/membership-purchase/contexts/MembershipPurchaseContext";
import { useCreateSubscription } from "src/sites/membership-purchase/hooks/useCreateSubscription";
import { useMembershipSignupPayments } from "src/sites/membership-purchase/hooks/useMembershipSignupPayments";
import { useTermsAndConditionsAcknowledgement } from "src/sites/membership-purchase/hooks/useTermsAndConditionsAcknowledgement";

export const PaymentBottomSheet = ({
	customerId,
	priceId,
	customerSignature,
	open,
	onClose,
	promoCodeId,
	referralCode,
}: {
	customerId: string;
	priceId: number;
	customerSignature?: string;
	open: boolean;
	onClose: () => void;
	promoCodeId?: string;
	referralCode?: string;
}) => {
	const elements = useElements();
	const stripe = useStripe();
	const { updateDefaultPaymentMethod } = useMembershipSignupPayments();
	const { setScreen, accountApproach, userData } =
		useMembershipPurchaseContext();
	const chargePerVehicle =
		accountApproach === EAccountApproach.ChargePerVehicleMulti ||
		accountApproach === EAccountApproach.ChargePerVehicleSingle;
	const { createUserSubscription, createVehicleSubscription } =
		useCreateSubscription();
	const { createTermsAndConditionsAcknowledgement } =
		useTermsAndConditionsAcknowledgement();
	const { showError } = useModalContext();

	const [submitting, setSubmitting] = useState(false);
	const [paymentSheetComplete, setPaymentSheetComplete] = useState(false);

	const handleSubmit = useCallback(async () => {
		if (!stripe || !elements) {
			return;
		}
		setSubmitting(true);
		const { error, setupIntent } = await stripe.confirmSetup({
			elements,
			redirect: "if_required",
		});
		if (error) {
			showError({
				headerText: "Card Collection Error",
				message:
					error.message ?? "An error occurred while collecting card details",
			});
		}
		if (setupIntent && setupIntent.payment_method && userData && !error) {
			try {
				const paymentMethodId = setupIntent.payment_method as string;
				// Update default payment method
				await updateDefaultPaymentMethod(customerId, paymentMethodId);
				// Create subscription
				if (chargePerVehicle && userData.vehicleId) {
					await createVehicleSubscription(
						userData.accountId,
						userData.vehicleId,
						priceId,
						paymentMethodId,
						promoCodeId,
						referralCode,
					);
				} else {
					await createUserSubscription(
						userData.accountId,
						priceId,
						paymentMethodId,
						promoCodeId,
						referralCode,
					);
				}
				// Create terms and conditions acknowledgement
				await createTermsAndConditionsAcknowledgement(
					userData.userId,
					customerSignature,
				);
				setSubmitting(false);
				setScreen(EMembershipPurchaseScreen.Success);
			} catch (error) {
				showError({
					headerText: "Subscription Error",
					message:
						"An error occurred while creating your subscription. Please contact an admin for support.",
				});
				setSubmitting(false);
			}
		}
	}, [
		elements,
		stripe,
		updateDefaultPaymentMethod,
		customerId,
		setScreen,
		chargePerVehicle,
		createUserSubscription,
		userData,
		priceId,
		createVehicleSubscription,
		createTermsAndConditionsAcknowledgement,
		customerSignature,
		referralCode,
		promoCodeId,
		showError,
	]);

	const handlePaymentElementChange = (
		event: StripePaymentElementChangeEvent,
	) => {
		if (event.complete) {
			setPaymentSheetComplete(true);
		} else {
			setPaymentSheetComplete(false);
		}
	};

	return (
		<AmpBottomSheet isOpen={open} onClose={onClose}>
			<AmpContainer
				padding={4}
				overflow="scroll"
				width="full"
				alignItems="initial"
			>
				<PaymentElement onChange={handlePaymentElementChange} />
				<AmpSpacer height={4} />
				<AmpButton
					label="Submit"
					onClick={handleSubmit}
					disabled={!paymentSheetComplete || submitting}
					width="wide"
					loading={submitting}
					colorType="secondary"
				/>
			</AmpContainer>
		</AmpBottomSheet>
	);
};
