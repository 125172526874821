import { TextField } from "@material-ui/core";
import { IAmpTextInputProps } from "./types/AmpTextInputTypes";
import styles from "./css/AmpTextInput.module.scss";

export const AmpTextInput = ({ label, placeholder, className, ...props }: IAmpTextInputProps) => {
    return (
        <div className={styles["amp-input-container"]}>
            <p className={styles["amp-input-label"]}>
                {label}
            </p>
            <TextField
                {...props}
                className={styles["amp-text-field"]}
                placeholder={placeholder ?? label}
                InputLabelProps={{
                    shrink: false,
                }}
                variant="outlined"
            />
        </div>
    );
};