import React from 'react';

export const AmpSuccessCheckmark = ({ className = '', size = 129 }) => {
  return (
    <div className={`inline-block ${className}`}>
      <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width={size} 
        height={size} 
        viewBox="0 0 129 129" 
        fill="none"
        className="w-full h-full"
      >
        <g clipPath="url(#clip0_13317_783)">
          <mask id="mask0_13317_783" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="0" y="0" width="129" height="129">
            <path d="M128.5 0.5H0.5V128.5H128.5V0.5Z" fill="white"/>
          </mask>
          <g mask="url(#mask0_13317_783)">
            <g opacity="0.832589">
              <path 
                d="M64.5 11.1814C93.4473 11.1814 116.95 34.6843 116.95 63.6316C116.95 92.5789 93.4473 116.082 64.5 116.082C35.5527 116.082 12.0498 92.5789 12.0498 63.6316C12.0498 34.6843 35.5527 11.1814 64.5 11.1814Z" 
                fill="#C7F1E0"
              />
              <path 
                d="M64.5 11.1814C93.4473 11.1814 116.95 34.6843 116.95 63.6316C116.95 92.5789 93.4473 116.082 64.5 116.082C35.5527 116.082 12.0498 92.5789 12.0498 63.6316C12.0498 34.6843 35.5527 11.1814 64.5 11.1814Z" 
                stroke="white" 
                strokeWidth="3.55595"
              />
            </g>
            <path 
              d="M64.5 19.5901C88.9216 19.5901 108.75 39.4185 108.75 63.8401C108.75 88.2617 88.9216 108.09 64.5 108.09C40.0784 108.09 20.25 88.2617 20.25 63.8401C20.25 39.4185 40.0784 19.5901 64.5 19.5901Z" 
              fill="#2CDA94"
            />
            <path 
              fillRule="evenodd" 
              clipRule="evenodd" 
              d="M85.1474 48.8971C86.5159 50.2211 86.552 52.4038 85.228 53.7724L59.228 80.6474C58.5894 81.3075 57.7138 81.6855 56.7955 81.6976C55.8771 81.7097 54.9919 81.3549 54.3361 80.7118L41.4611 68.0868C40.1015 66.7536 40.0801 64.5707 41.4133 63.2111C42.7465 61.8515 44.9294 61.8301 46.289 63.1633L56.6856 73.358L80.2721 48.9778C81.5961 47.6092 83.7788 47.5731 85.1474 48.8971Z" 
              fill="white"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_13317_783">
            <rect width="128" height="128" fill="white" transform="translate(0.5 0.5)"/>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};