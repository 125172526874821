// AmpSpacer.tsx
import { IAmpSpacerProps } from "./types/AmpSpacerTypes";
import styles from "./css/AmpSpacer.module.scss";
import { getSpacing } from "../utils/get-spacing";

export const AmpSpacer = ({ height, width }: IAmpSpacerProps) => {
	const getClassName = () => {
		const classes = [styles["amp-spacer"]];

		return classes.filter(Boolean).join(" ");
	};

	const style = {
		height: height ? getSpacing(height) : undefined,
		width: width ? getSpacing(width) : undefined,
	};

	return <div className={getClassName()} style={style} />;
};
