import { useCallback, useEffect, useState } from "react";
import { useUserZipLocation } from "../../../hooks/useUserZipLocation";

const _ZIP_CODE_SESSION_STORAGE_KEY = "zip_code";

export const useUserZipCode = (defaultZipCode: string | undefined) => {
	const [zipCode, setZipCode] = useState("");
	const { getZipCodeFromCoordinates } = useUserZipLocation();

	const getZipCodeFromCurrentLocation = useCallback(
		async (position: GeolocationPosition): Promise<string | undefined> => {
			const { latitude, longitude } = position.coords;
			const coords = {
				latitude,
				longitude,
			};
			return getZipCodeFromCoordinates(coords);
		},
		[getZipCodeFromCoordinates],
	);

	const setZipCodeFromCurrentLocation = useCallback(
		async (position: GeolocationPosition) => {
			const userZipCode = await getZipCodeFromCurrentLocation(position);
			if (userZipCode) {
				setZipCode(userZipCode);
				sessionStorage.setItem(_ZIP_CODE_SESSION_STORAGE_KEY, userZipCode);
			} else if (defaultZipCode) {
				setZipCode(defaultZipCode);
			}
		},
		[getZipCodeFromCurrentLocation, defaultZipCode],
	);

	const handleInitialZipCodeSet = useCallback(() => {
		const savedZipCode = sessionStorage.getItem(_ZIP_CODE_SESSION_STORAGE_KEY);
		if (defaultZipCode && !savedZipCode) {
			navigator.geolocation.getCurrentPosition(
				(position) => setZipCodeFromCurrentLocation(position),
				() => setZipCode(defaultZipCode),
			);
		} else if (savedZipCode) {
			setZipCode(savedZipCode);
		}
	}, [defaultZipCode, setZipCode, setZipCodeFromCurrentLocation]);

	useEffect(() => {
		if (!zipCode) {
			handleInitialZipCodeSet();
		}
	}, [
		handleInitialZipCodeSet,
		zipCode,
	]);

	return {
		getZipCodeFromCurrentLocation,
		zipCode,
		setZipCode,
	};
};
