import React, { FC, PropsWithChildren, useEffect } from "react";
import { useLoadingContext } from "../../contexts/LoadingContext";

export const PauseLoading: FC<PropsWithChildren> = ({ children }) => {
	// Get loading context
	const { setLoading } = useLoadingContext();
	// Set not loading
	useEffect(() => {
		// Set not loading
		setLoading(false);
		// Return set loading
		return () => setLoading(true);
	}, [setLoading]);
	// Return children
	return <>{children}</>;
};
