import { flow, sortBy } from "lodash/fp";
import { IAutowashLocation } from "amp";
import { getDistanceToCoordinates } from "../utils/getDistanceToCoordinates";
import { useDynamicApi } from "./useApi";
import { useCallback, useMemo } from "react";
import {
	mapboxFromCoordinates,
	mapboxFromZipCode,
} from "../utils/mapbox-api-requests";
import { Lazy } from "amp";
export type LocationWithDistance = IAutowashLocation & {
	distanceToUser?: number;
};

export interface ILocationCoordinates {
	latitude: number;
	longitude: number;
}

const findCoordinatesFromZipCode = async (
	zipCode: string,
): Promise<ILocationCoordinates | undefined> => {
	if (!zipCode) {
		return undefined;
	}
	try {
		const closestLocation = await mapboxFromZipCode(zipCode);
		if (!closestLocation) {
			return undefined;
		}
		const { latitude, longitude } = closestLocation;
		// Return closest found location coordinates
		return {
			latitude,
			longitude,
		};
	} catch (e) {
		console.log(e);
		return undefined;
	}
};

const mapLocationWithDistance = (
	locations: IAutowashLocation[],
	userLocation: ILocationCoordinates | null | undefined,
	take?: number,
): LocationWithDistance[] => {
	const executeTake = (_locations: LocationWithDistance[]) =>
		typeof take === "number" ? _locations.slice(0, take) : _locations;

	return flow(
		() =>
			locations?.map((location): LocationWithDistance => {
				const distanceToUser = userLocation
					? getDistanceToCoordinates(userLocation, location)
					: undefined;

				return { ...location, distanceToUser };
			}),
		sortBy([
			(l: LocationWithDistance) => l?.distanceToUser,
			(l: LocationWithDistance) => l?.name,
		]),
		executeTake,
	)();
};

const getZipCodeFromCoordinates = async (
	coordinates: ILocationCoordinates,
): Promise<string | undefined> => {
	return mapboxFromCoordinates(coordinates.latitude, coordinates.longitude);
};

export const useUserZipLocation = () => {
	const { dynamic } = useDynamicApi();
	const lazyLocations = useMemo(() =>
		new Lazy(() => dynamic("locations", {
			condition: {
				left: { column: "is_active" },
				operator: "EQ",
				right: true,
			},
		})), []);

	const getLocationFromCoordinates = useCallback(
		async (coordinates: ILocationCoordinates | undefined) => {
			const locations = await lazyLocations.value;
			const take = 1;
			const displayLocationsUserZip = mapLocationWithDistance(
				locations ?? [],
				coordinates,
				take,
			);
			return displayLocationsUserZip[0];
		},
		[lazyLocations],
	);

	const getClosestLocation = useCallback(
		async (
			zipCode: string | undefined,
		): Promise<LocationWithDistance | undefined> => {
			if (!zipCode) {
				return undefined;
			}
			const coordinates = await findCoordinatesFromZipCode(zipCode);
			if (!coordinates) {
				return undefined;
			}
			return getLocationFromCoordinates(coordinates);
		},
		[getLocationFromCoordinates],
	);

	return {
		getClosestLocation,
		getZipCodeFromCoordinates,
	};
};
