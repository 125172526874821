import { useCallback } from "react";
import {
	EEndpointType,
	IEmailUnsubscribeRequest,
	IEmailUnsubscribeResponse,
} from "amp";
import { useApi } from "../../../hooks/useApi";

const useEmailUnsubscribeApi = () =>
	useApi(EEndpointType.Anonymous, "user");

export const useEmailUnsubscribeEndpoints = () => {
	const { post } = useEmailUnsubscribeApi();

	const updateNotificationPreferences = useCallback(
		(
			request: IEmailUnsubscribeRequest,
		): Promise<IEmailUnsubscribeResponse> => {
			return post<IEmailUnsubscribeRequest, IEmailUnsubscribeResponse>(
				"email-unsubscribe",
				request,
			);
		},
		[post],
	);

	return {
		updateNotificationPreferences,
	};
};
