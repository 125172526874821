import React, {
	Context,
	createContext,
	FC,
	PropsWithChildren,
	useContext as useContextReact,
} from "react";

export type XContextHook<TContext> = () => TContext;

export type XContextProvider<TContext> = FC<
	PropsWithChildren<{ value: TContext }>
>;

export interface IInitializeContextInput<TContext> {
	debugName: string;
	createInitial?: (helpers: { throwNotAvailable: () => never }) => TContext;
}

export type XInitializeContextOutput<TContext> = [
	Context<TContext | undefined>,
	XContextHook<TContext>,
	XContextProvider<TContext>,
];

export function initializeContext<TContext>(
	debugName: string,
): XInitializeContextOutput<TContext>;
// eslint-disable-next-line no-redeclare
export function initializeContext<TContext>(
	input: IInitializeContextInput<TContext>,
): XInitializeContextOutput<TContext>;
// eslint-disable-next-line no-redeclare
export function initializeContext<TContext>(
	inputOrDebugName: IInitializeContextInput<TContext> | string,
): XInitializeContextOutput<TContext> {
	// Get debug name
	const debugName =
		typeof inputOrDebugName === "string"
			? inputOrDebugName
			: inputOrDebugName.debugName;
	// Get create initial
	const createInitial =
		typeof inputOrDebugName === "string"
			? undefined
			: inputOrDebugName.createInitial;
	// Create throw not available
	const throwNotAvailable = () => {
		// Throw error
		throw new Error(
			`The specific functionality you are trying to access/use on context (${debugName}) is not available.`,
		);
	};
	// Create initial
	const initial = createInitial
		? createInitial({ throwNotAvailable })
		: undefined;
	// Create context
	const context = createContext(initial);
	// Create use context
	const useContext: XContextHook<TContext> = () => {
		// Get value
		const value = useContextReact(context);
		// Check if value does not exist
		if (value === undefined) {
			// Throw error
			throw new Error(`The requested context (${debugName}) is not available.`);
		}
		// Return value
		return value;
	};
	// Create provider
	const provider: XContextProvider<TContext> = ({ value, children }) => (
		<context.Provider value={value}>{children}</context.Provider>
	);
	// Return context and use context
	return [context, useContext, provider];
}
