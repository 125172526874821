import React from "react";
import { useBrandingContext } from "../../contexts/BrandingContext";
import { Helmet } from "react-helmet";

interface Props {
	title?: string;
}
export default function AmpHead({
	title,
}: Props) {
	const { images, info } = useBrandingContext();
	const favicon = images?.WebAppFavicon?.image_url__c;
	return (
		<Helmet>
			<title>{title ?? info?.name}</title>
			<link rel='icon' href={favicon ? favicon : "/favicon.ico"} />
		</Helmet>
	);
}
