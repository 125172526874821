import { getDistance } from "geolib";
import { IAutowashLocation } from "amp";
import { ILocationCoordinates } from "../hooks/useUserZipLocation";

export const getDistanceToCoordinates = (
	userLocation: ILocationCoordinates,
	location: IAutowashLocation,
): number => {
	const {
		geolocation__longitude__s: longitude,
		geolocation__latitude__s: latitude,
	} = location;
	const distanceToCoordinates = getDistance(userLocation, {
		latitude,
		longitude,
	});
	return distanceToCoordinates;
};
