import React, { FC, PropsWithChildren } from "react";
import { PrepaidWashPromotionsProvider } from "../contexts/PrepaidWashPromotionsContext";

export const PrepaidWashesProviders: FC<PropsWithChildren> = ({
	children,
}): JSX.Element => {
	return (
		<PrepaidWashPromotionsProvider>{children}</PrepaidWashPromotionsProvider>
	);
};
