import React, { ReactNode } from "react";
import { useBrandingContext } from "../../contexts/BrandingContext";
import { AmpModal } from "./AmpModal";
import { Grid, Typography, useTheme } from "@material-ui/core";
import { AmpButtonOld } from "../ui-elements/buttons/Buttons";

export type ErrorModalProps = {
	message: string;
	onDismiss: () => void;
	visible: boolean;
	children?: ReactNode;
	closeButtonText?: string;
	headerText?: string;
};
export const ErrorModal = ({
	headerText,
	message,
	onDismiss,
	visible,
	children,
}: ErrorModalProps) => {
	const { images } = useBrandingContext();
	const theme = useTheme();
	return (
		<AmpModal
			visible={visible}
			onClose={onDismiss}
			modalStyles={{
				paddingBottom: 20,
				borderTopWidth: 4,
				borderColor: theme.palette.error.light,
				paddingHorizontal: "5%",
			}}
		>
			<Grid container direction="column" alignItems="center" spacing={2}>
				<Grid item>
					{!!images?.Error && (
						<img
							src={images.Error.image_url__c}
							alt="Error image"
							style={{
								marginTop: 20,
							}}
							height={200}
							width={200}
						/>
					)}
				</Grid>
				<Grid item>
					<Typography
						style={{
							fontSize: 24,
							textAlign: "center",
							color: theme.palette.error.light,
							fontWeight: "bold",
						}}
					>
						{headerText || "Oh, no! Something went wrong!"}
					</Typography>
				</Grid>
				<Grid item>
					<Typography
						style={{
							textAlign: "center",
							marginBottom: 10,
							fontSize: 18,
						}}
					>
						{message}
					</Typography>
				</Grid>
				{children}
				<Grid item style={{ width: "20%" }}>
					<AmpButtonOld
						buttonText="Close"
						onClick={onDismiss}
						size="medium"
						fullWidth
					/>
				</Grid>
			</Grid>
		</AmpModal>
	);
};
