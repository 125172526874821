import React, { FC, PropsWithChildren, useMemo } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { initializeContext } from "src/utils/initialize-context";
import { useBrandingContext } from "src/contexts/BrandingContext";

interface StripeProviderProps extends PropsWithChildren {
	setupIntentSecret: string;
}

export const [
	StripeSetupIntentContext,
	useStripeSetupIntentContext,
	StripeSetupIntentContextProvider,
] = initializeContext("Stripe");

export const StripeSetupIntentProvider: FC<StripeProviderProps> = ({
	children,
	setupIntentSecret,
}): JSX.Element => {
	const { info } = useBrandingContext();

	const stripePublicKey = info?.stripe_public_key__c;
	const accountId = info?.stripe_account_id;
	const stripePromise = useMemo(
		() =>
			stripePublicKey
				? loadStripe(stripePublicKey, { stripeAccount: accountId })
				: null,
		[stripePublicKey],
	);

	return (
		<StripeSetupIntentContextProvider value={{}}>
			<Elements
                key={setupIntentSecret}
				stripe={stripePromise}
				options={{ clientSecret: setupIntentSecret }}
			>
				{children}
			</Elements>
		</StripeSetupIntentContextProvider>
	);
};
