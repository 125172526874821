import { EEndpointType, IAcknowledgment } from "amp";
import { useApi } from "src/hooks/useApi";
import { useFetchData } from "src/hooks/useFetchData";

const termsAndConditionsKey = "TERMS_AND_CONDITIONS";

export const useTermsAndConditionsAcknowledgement = () => {
	const { get, post } = useApi(EEndpointType.Anonymous, "acknowledgments");

	const { data: termsAndConditionsAcknowledgement, loading } = useFetchData(
		() =>
			get<IAcknowledgment | undefined>(`get-by-key/${termsAndConditionsKey}`),
	);

	const createTermsAndConditionsAcknowledgement = async (
		userId: string,
		signature?: string,
	) => {
		await post<
			{ acknowledgmentKey: string; userId: string; signature?: string },
			void
		>("create-acknowledgment-acceptance", {
			acknowledgmentKey: termsAndConditionsKey,
			userId,
			signature,
		});
	};

	return {
		termsAndConditionsAcknowledgement,
		loading,
		createTermsAndConditionsAcknowledgement,
	};
};
