import { EIntervalType, IProductPriceWithJson } from "amp";
import { useEffect, useState } from "react";
import { useFetchData } from "src/hooks/useFetchData";
import { toDollars } from "src/utils/toDollars";
import { useMembershipPurchaseContext } from "../../contexts/MembershipPurchaseContext";
import { useMembershipSignupPayments } from "../../hooks/useMembershipSignupPayments";
import { ProductPaymentScreen } from "./components/ProductPaymentScreen";
import { AmpRadioCard } from "src/components/ui-core/inputs/AmpRadioCard";
import { AmpContainer } from "src/components/ui-core/containers/AmpContainer";
import { AmpText } from "src/components/ui-core/typography/AmpText";
import { AmpButton } from "src/components/ui-core/buttons/AmpButton";
import { AmpDivider } from "src/components/ui-core/spacers/AmpDivider";
import { Grid, LinearProgress } from "@material-ui/core";
import { AmpCard } from "src/components/ui-core/containers/AmpCard";

export const PurchaseProductScreen = () => {
	const { product, userData } = useMembershipPurchaseContext();
	const { getSetupIntentClientSecret } = useMembershipSignupPayments();
	const [priceId, setPriceId] = useState<number | undefined>();
	const [showPrices, setShowPrices] = useState(true);

	const { data: clientSecret, loading: loadingClientSecret } = useFetchData(
		() => {
			if (!userData) return Promise.reject("No user data");
			return getSetupIntentClientSecret(userData.stripeCustomerId);
		},
	);

	const prices = product?.prices?.filter(
		(price) => price.active && price.parent_product_price_id === null,
	);

	useEffect(() => {
		if (prices?.length === 1) {
			setPriceId(prices[0].id);
			setShowPrices(false);
		}
	}, []);

	if (loadingClientSecret || !clientSecret) {
		return (
			<AmpContainer width="full" height="full">
				<AmpContainer
					padding={4}
					direction="column"
					alignItems="center"
					width="full"
					color="surface"
					flexGrow={1}
				>
					<Grid container item direction="column" spacing={3} md={6} sm={12}>
						<Grid item>
							<AmpCard padding={6} gap={6} flexDirection="column">
								{loadingClientSecret ? (
									<LinearProgress />
								) : (
									<AmpText type="body_lg">
										Error loading account information. Please try again.
									</AmpText>
								)}
							</AmpCard>
						</Grid>
					</Grid>
				</AmpContainer>
			</AmpContainer>
		);
	}

	if (!priceId || showPrices) {
		return (
			<>
				<AmpContainer height="full" width="full">
					<AmpContainer
						width="full"
						flexGrow={1}
						color="surface"
						alignItems="center"
						padding={4}
					>
						<Grid container direction="row" md={6} sm={12}>
							<AmpContainer gap={6} width="full" alignItems="center">
								<AmpText type="body_lg" fontWeight="bold">
									How would you like to pay?
								</AmpText>
								<AmpContainer gap={4} width="full">
									{prices?.map((price) => (
										<PriceCard
											price={price}
											onClick={(priceId: number) => setPriceId(priceId)}
											selectedPriceId={priceId}
										/>
									))}
								</AmpContainer>
							</AmpContainer>
						</Grid>
					</AmpContainer>
					<AmpDivider />
					<AmpContainer padding={4} width="full" alignItems="center">
						<Grid container direction="row" md={6} sm={12}>
							<AmpButton
								onClick={() => setShowPrices(false)}
								label="Continue"
								width="wide"
								colorType="secondary"
								disabled={!priceId}
							/>
						</Grid>
					</AmpContainer>
				</AmpContainer>
			</>
		);
	}

	return (
		<ProductPaymentScreen
			priceId={priceId}
			setupIntentClientSecret={clientSecret}
			onGoBack={() => setShowPrices(true)}
		/>
	);
};
const PriceCard = ({
	price,
	onClick,
	selectedPriceId,
}: {
	price: IProductPriceWithJson;
	onClick: (priceId: number) => void;
	selectedPriceId?: number;
}) => {
	const priceText = toDollars(price.metadata.Tiers[0].AmountPerUnit);

	const intervalText =
		price.interval_count > 1
			? getIntervalText(price.interval_type) + "s"
			: getIntervalText(price.interval_type);

	const frequency =
		"Every " +
		`${price.interval_count > 1 ? `${price.interval_count} ` : ""}` +
		intervalText;

	return (
		<AmpRadioCard
			onClick={() => {
				onClick(price.id);
			}}
			checked={price.id === selectedPriceId}
		>
			<AmpContainer
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				width="full"
				flex={1}
			>
				<AmpText type="body_md" fontWeight="bold">
					{frequency}
				</AmpText>
				<AmpText type="h5" fontWeight="bold" color="primary">
					{priceText}
				</AmpText>
			</AmpContainer>
		</AmpRadioCard>
	);
};

const getIntervalText = (intervalType: EIntervalType) => {
	switch (intervalType) {
		case EIntervalType.MONTH:
			return "month";
		case EIntervalType.YEAR:
			return "year";
		case EIntervalType.WEEK:
			return "week";
		case EIntervalType.DAY:
			return "day";
		default:
			return "";
	}
};
