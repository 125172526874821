import environment from "../environment";

const _BASE_URL = "https://api.mapbox.com/geocoding/v5/mapbox.places/";
const ACCESS_TOKEN = environment.mapbox.apiKey;

interface IFromZipCode {
	latitude: number;
	longitude: number;
}

interface IMatchedResult {
	id: string;
	text: string;
}

function generateAPIRequestURL({ requestData }: { requestData: string }) {
	const request = `${requestData}.json?`;
	const accessToken = `access_token=${ACCESS_TOKEN}`;
	return _BASE_URL.concat(request, accessToken);
}

export const mapboxFromZipCode = async (
	zipCode: string,
): Promise<IFromZipCode | undefined> => {
	const requestURL = generateAPIRequestURL({ requestData: zipCode });
	const response = await fetch(requestURL).catch((err) =>
		console.error(err, "Mapbox API Error"),
	);
	const result = await response?.json();
	const coordinates = result?.features[0]?.center;
	if (coordinates) {
		return {
			longitude: coordinates[0],
			latitude: coordinates[1],
		};
	}
	return undefined;
};

export const mapboxFromCoordinates = async (
	latitude: number,
	longitude: number,
): Promise<string | undefined> => {
	const requestURL = generateAPIRequestURL({
		requestData: `${longitude},${latitude}`,
	});
	const response = await fetch(requestURL).catch((err) =>
		console.warn(err, "Mapbox API Error, fall back to default zip code"),
	);
	const result = await response?.json();
	const matchedResults: IMatchedResult[] | undefined =
		result.features[0].context;
	const zipCode = matchedResults?.filter(({ id }) =>
		id.includes("postcode"),
	)[0];

	return zipCode?.text;
};
