import { CustomerInfoScreen } from "./screens/collect-info-screen/CustomerInfoScreen";
import {
	EMembershipPurchaseScreen,
	useMembershipPurchaseContext,
} from "./contexts/MembershipPurchaseContext";
import { PurchaseProductScreen } from "./screens/purchase-product-screen/PurchaseProductScreen";
import { PaymentSuccessScreen } from "./screens/payment-success/PaymentSuccessScreen";

export default function MembershipPurchase() {
	const { screen } = useMembershipPurchaseContext();
	return (
		<>
			{(() => {
				switch (screen) {
					case EMembershipPurchaseScreen.Info:
						return <CustomerInfoScreen />;
					case EMembershipPurchaseScreen.Payment:
						return <PurchaseProductScreen />;
					case EMembershipPurchaseScreen.Success:
						return <PaymentSuccessScreen/>;
					default:
						return null;
				}
			})()}
		</>
	);
}
