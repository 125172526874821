import { IAmpRadioCardProps } from "./types/AmpRadioCardTypes";
import { AmpCard } from "../containers/AmpCard";
import { Radio } from "@material-ui/core";
import { AmpContainer } from "../containers/AmpContainer";

export const AmpRadioCard = ({
	onClick,
	children,
	...props
}: IAmpRadioCardProps) => {
	return (
		<AmpCard flexDirection="row" padding={4} onClick={onClick} fullWidth>
			<AmpContainer
				direction="column"
				alignItems="center"
				justifyContent="center"
			>
				<Radio {...props} />
			</AmpContainer>
			{children}
		</AmpCard>
	);
};
