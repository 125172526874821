import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import environment from "../environment";

const defaultBrowserHistory = {
	url: "/",
	location: { pathName: "" },
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	listen: () => {},
};	

let browserHistory = defaultBrowserHistory;

if (typeof window !== "undefined") {
	browserHistory = {	...browserHistory, ...window.history };
	const url = window?.history?.state?.url;
	browserHistory.location.pathName = url;
}

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
	config: {
		instrumentationKey: environment.appInsights.instrumentationKey,
		maxBatchInterval: 15000,
		maxBatchSizeInBytes: 10000,
		extensions: [reactPlugin],
		extensionConfig: {
			[reactPlugin.identifier]: { history: browserHistory}
		},
	}
});

if (typeof window !== "undefined") {
	appInsights.loadAppInsights();
}

export { appInsights, reactPlugin };

