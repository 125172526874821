import { useCallback, useEffect } from "react";
import { ITermsAndHelpLink } from "amp";
import { useStateWithMounted } from "src/hooks/useStateWithMounted";
import { useDynamicApi } from "src/hooks/useApi";

export const useTermsAndHelpLink = (
	key: string,
): [
	(
		| Pick<ITermsAndHelpLink, "name" | "icon__c" | "ordinal__c" | "url__c">
		| undefined
	),
	boolean,
] => {
	const [loading, setLoading] = useStateWithMounted(true);
	const [termsAndHelpLink, setTermsAndHelpLink] =
		useStateWithMounted<ITermsAndHelpLink>();
	const { dynamic } = useDynamicApi();

	const fetchTermsAndHelpLink = useCallback(async () => {
		const data = await dynamic("termsAndHelpLink", {
			condition: {
				left: { column: "key" },
				operator: "EQ",
				right: key,
			},
			columns: ["name", "icon__c", "ordinal__c", "url__c"],
		});

		setTermsAndHelpLink(data.length ? data[0] : undefined);
		setLoading(false);
	}, [key, dynamic, setLoading, setTermsAndHelpLink]);

	useEffect(() => {
		fetchTermsAndHelpLink();
	}, []);

	return [termsAndHelpLink, loading];
};
