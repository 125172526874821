import { escapeRegExp } from "lodash";
import { Fragment, Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import ErrorBoundary from "./components/ErrorBoundary";
import { LoadingScreen } from "./screens/loading/LoadingScreen";
import { combineComponents } from "./utils/combineComponents";
import { PrepaidWashesLayout } from "./sites/prepaid-washes/layouts/prepaid-washes-layout";
import { EmailUnsubscribeLayout } from "./sites/email-unsubscribe/layouts/email-unsubscribe-layout";
import { MembershipPurchaseLayout } from "./sites/membership-purchase/layouts/membership-purchase-layout";

interface IRoute {
	path?: string;
	guard?: any;
	layout?: any;
	layoutProps?: any;
	component?: any;
	routes?: IRoute[];
}

type XRoutes = IRoute[];

const getRouteGuardsOrFragment = (guard?: any) => {
	if (!guard) {
		return Fragment;
	}
	if (Array.isArray(guard)) {
		return combineComponents(...guard);
	}
	return guard;
};

export const renderRoutes = (
	routes: XRoutes = [],
	parentRoutePath?: string,
): JSX.Element[] => {
	return routes.map((route) => {
		const Guard = getRouteGuardsOrFragment(route.guard);
		const Layout = route.layout || Fragment;
		const Component = route.component;
		// convert absolute paths to relative paths (react-router v5 -> v6)
		// this lets us avoid converting all routes to relative paths
		let routePath = route.path;
		if (parentRoutePath) {
			const parentRoutePathWithoutWildcard = parentRoutePath.replace(
				/\/\*$/,
				"",
			);
			const removeParentPathRegex = new RegExp(
				`^${escapeRegExp(parentRoutePathWithoutWildcard)}/`,
			);
			routePath = route.path?.replace(removeParentPathRegex, "");
		}
		return (
			<Route
				key={route.path}
				path={routePath}
				errorElement={<ErrorBoundary />}
				element={
					<Guard>
						<Layout {...route.layoutProps}>
							{route.routes ? (
								<Routes>{renderRoutes(route.routes, route.path)}</Routes>
							) : (
								<Suspense fallback={<LoadingScreen />}>
									<Component />
								</Suspense>
							)}
						</Layout>
					</Guard>
				}
			/>
		);
	});
};

export const routes: XRoutes = [
	{
		path: "*",
		routes: [
			{
				path: "/prepaid-washes/*",
				layout: PrepaidWashesLayout,
				routes: [
					{
						path: "",
						component: lazy(() => import("./sites/prepaid-washes/index")),
					},
					{
						path: "/promotions/:promotionId",
						component: lazy(
							() => import("./sites/prepaid-washes/promotions/index"),
						),
					},
					{
						path: "/confirmation/:promotionId/:confirmationCode",
						component: lazy(
							() => import("./sites/prepaid-washes/confirmation/index"),
						),
					},
				],
			},
			{
				path: "/email-unsubscribe/:email/:topic",
				layout: EmailUnsubscribeLayout,
				component: lazy(() => import("./sites/email-unsubscribe/index")),
			},
			{
				path: "/membership-purchase/:kioskId/:productId/:phoneNumber",
				layout: MembershipPurchaseLayout,
				component: lazy(() => import("./sites/membership-purchase/index")),
			},
			{
				path: "/membership-purchase/unauthorized",
				component: lazy(() => import("./sites/membership-purchase/screens/unauthorized-user-screen/UnauthorizedUserScreen")),
			},
		],
	},
];
