import React, { useEffect, useMemo, FC, PropsWithChildren, useState } from "react";
import { EEndpointType, IBrandingData } from "amp";
import { useApi } from "../hooks/useApi";
import { useFetchData } from "../hooks/useFetchData";
import { initializeContext } from "../utils/initialize-context";
import { IGeneralInformation } from "amp";
import { IBrandImage } from "amp";
import { keyBy, mapValues } from "lodash";
import { IsLoading } from "../components/loading/IsLoading";
import { EConfigurableText, ITheme, IMenuOption } from "amp";
interface IBrandingContext {
	info: IGeneralInformation | undefined;
	images: Record<string, IBrandImage> | undefined;
	configurableTextsKeyed: Record<EConfigurableText, string | null>;
	theme: ITheme;
	menuOptions: IMenuOption[] | undefined;
}

export const [BrandingContext, useBrandingContext, BrandingContextProvider] =
	initializeContext<IBrandingContext>("Branding");

export const BrandingProvider: FC<PropsWithChildren<IBrandingContext>> = ({
	children,
}): JSX.Element => {
	const { get } = useApi(EEndpointType.Anonymous, "branding");
	const { data: brandingInfo, loading } = useFetchData(() =>
		get<IBrandingData>("get-branding-data"),
	);

	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		if (!loaded && brandingInfo && !loading) {
			setLoaded(true);
		}
	}, [loaded, brandingInfo, loading]);

	const getInfo = (): IGeneralInformation | undefined => {
		return brandingInfo?.generalInformation ?? undefined;
	};

	const _images = brandingInfo?.brandImages;
	const getImages = (): Record<string, IBrandImage> => {
		return keyBy(_images, (i) => i.key__c) ?? undefined;
	};

	const getConfigurableTextsKeys = (): Record<EConfigurableText, string | null> => {
		const keyedByName = keyBy(brandingInfo?.configurableTexts, "name");
		return mapValues(keyedByName, "value__c") as Record<EConfigurableText, string | null>;
	};

	const getMenuOptions = (): IMenuOption[] | undefined => {
		return brandingInfo?.menuOptions ?? undefined;
	};

	const info = getInfo();

	const images = useMemo(() => getImages(), [_images]);

	const configurableTextsKeyed = getConfigurableTextsKeys();

	const menuOptions = getMenuOptions();

	if (!loaded || !brandingInfo) {
		return <IsLoading />;
	}

	const theme = brandingInfo.defaultTheme;
	return (
		<BrandingContextProvider value={{ info, images, configurableTextsKeyed, theme, menuOptions }}>
			{children}
		</BrandingContextProvider>
	);
};
