import React, { FC, PropsWithChildren, useMemo } from "react";
import { useUpdateNotificationPreferences } from "../hooks/useUpdateNotificationPreferences";
import { EMessageTopic } from "amp";
import { initializeContext } from "../../../utils/initialize-context";
import { useParams } from "react-router-dom";

interface IEmailUnsubscribeContext {
	loading: boolean;
	email: string;
	topic: EMessageTopic;
	success: boolean | undefined;
}

export const [
	EmailUnsubscribeContext,
	useEmailUnsubscribeContext,
	EmailUnsubscribeContextProvider,
] = initializeContext<IEmailUnsubscribeContext>("Email Unsubscribe");

export const EmailUnsubscribeProvider: FC<PropsWithChildren> = ({
	children,
}): JSX.Element => {
	const { email, topic } = useParams() as {
		email: string;
		topic: EMessageTopic;
	};

	const maskedEmail = useMemo(() => {
		return email
			? email.charAt(0) + "..." + email.substring(email.indexOf("@"))
			: "";
	}, [email]);

	const { loading: updatingUser, success } = useUpdateNotificationPreferences({
		id: email,
		topic,
	});

	return (
		<EmailUnsubscribeContextProvider
			value={{
				loading: updatingUser,
				email: maskedEmail,
				topic,
				success,
			}}
		>
			{children}
		</EmailUnsubscribeContextProvider>
	);
};
