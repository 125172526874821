import { useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import styles from "./SignaturePad.module.scss";

interface ISignaturePadProps {
	onBegin?: () => void;
	onEnd: (base64ImageData: string) => void;
	onPadContentChange?: (isEmpty: boolean) => void;
}

export const SignaturePad = ({
	onBegin,
	onEnd,
	onPadContentChange,
}: ISignaturePadProps) => {
	const signatureCanvasRef = useRef<SignatureCanvas>(null);

	const handleSignatureBegin = () => {
		if (onBegin) {
			onBegin();
		}
	};

	const handleSignatureEnd = () => {
		onEnd(signatureCanvasRef.current?.toDataURL() || "");
		if (onPadContentChange) {
			onPadContentChange(signatureCanvasRef.current?.isEmpty() ?? true);
		}
	};
	return (
		<SignatureCanvas
			clearOnResize={false}
			ref={signatureCanvasRef}
			onBegin={handleSignatureBegin}
			onEnd={handleSignatureEnd}
			canvasProps={{
				className: styles["signature-pad"],
				style: {
					border: "1px solid var(--color-border-default, #DEE3EE)",
					borderRadius: "4px",
				},
			}}
		/>
	);
};
