import { v4 as uuidv4 } from 'uuid';
import React, { Component, ReactNode } from 'react';
import { ErrorModal } from './modal/ErrorModal';

interface IDisplayError {
  id: string;
  message: string;
  name?: string;
}

class ErrorBoundary extends Component<
  { children?: ReactNode },
  { error: IDisplayError | null }
> {
  constructor(props: { children?: React.ReactNode; }) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error) {
    return { error: { ...error, id: uuidv4() } };
  }

  componentDidCatch(error: Error) {
    // We can also log the error to an error reporting service here
    console.error(error);
  }

  render() {
    // Uncaught error
    if (this.state.error) {
      return (
        <ErrorModal
          visible={true}
          headerText="An error occurred"
          message={this.state.error.message}
          onDismiss={() => this.setState({ error: null })}
        />
      );
    }
    return this.props.children ?? null;
  }
}

export default ErrorBoundary;
