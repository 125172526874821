import { IAmpDividerProps } from "./types/AmpDividerTypes";
import styles from "./css/AmpDivider.module.scss";

export const AmpDivider = ({
	orientation = "horizontal",
}: IAmpDividerProps) => {
	const getClassName = () => {
		const classes = [styles["amp-divider"], styles[orientation]];

		return classes.filter(Boolean).join(" ");
	};

	return <div className={getClassName()} />;
};
