import {
	EEndpointType,
	IAnonymousPreviewNewInvoice,
	IInvoiceResponse,
} from "amp";
import { useApi } from "src/hooks/useApi";
import { useMembershipPurchaseContext } from "../contexts/MembershipPurchaseContext";
import { useCallback } from "react";

export const usePreviewSubscriptionInvoice = () => {
	const { post } = useApi(EEndpointType.Anonymous, "invoice");
	const {
		kiosk: { location_id },
	} = useMembershipPurchaseContext();

	const previewSubscriptionInvoice = useCallback(
		async (accountId: string, priceId: number, promoCodeId?: string) => {
			return await post<IAnonymousPreviewNewInvoice, IInvoiceResponse>(
				"preview-subscription-invoice",
				{
					accountId,
					promoCodeId,
					locationId: location_id,
					addPriceData: {
						id: priceId,
						quantity: 1,
					},
					prorationBehavior: "always_invoice",
					billingCycleAnchor: undefined,
				},
			);
		},
		[post, location_id],
	);

	return previewSubscriptionInvoice;
};
