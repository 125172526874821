import {
	EEndpointType,
	ICreateUserAnonymousRequest,
	IUpdateUserAnonymousRequest,
	XAmpUserContext,
} from "amp";
import { useCallback } from "react";
import { useApi } from "src/hooks/useApi";

export const useMembershipPurchaseUser = () => {
	const { post } = useApi(EEndpointType.Anonymous, "user");

	const createUser = useCallback(
		async (request: ICreateUserAnonymousRequest) => {
			return await post<ICreateUserAnonymousRequest, XAmpUserContext>(
				"create-user",
				request,
			);
		},
		[post],
	);

	const updateUser = useCallback(
		async (request: IUpdateUserAnonymousRequest) => {
			return await post<IUpdateUserAnonymousRequest, XAmpUserContext>(
				"update-user",
				request,
			);
		},
		[post],
	);
	return { createUser, updateUser };
};
