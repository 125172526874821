import { Grid, LinearProgress } from "@material-ui/core";
import { EAcknowledgementSignature } from "amp";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SignaturePad } from "src/components/signature-pad/SignaturePad";
import { AmpButton } from "src/components/ui-core/buttons/AmpButton";
import { AmpCard } from "src/components/ui-core/containers/AmpCard";
import { AmpContainer } from "src/components/ui-core/containers/AmpContainer";
import { AmpCheckbox } from "src/components/ui-core/inputs/AmpCheckbox";
import { AmpDivider } from "src/components/ui-core/spacers/AmpDivider";
import { AmpText } from "src/components/ui-core/typography/AmpText";
import { useFetchData } from "src/hooks/useFetchData";
import { useMembershipPurchaseContext } from "src/sites/membership-purchase/contexts/MembershipPurchaseContext";
import { StripeSetupIntentProvider } from "src/sites/membership-purchase/contexts/StripeSetupIntentContext";
import { usePreviewSubscriptionInvoice } from "src/sites/membership-purchase/hooks/usePreviewSubscriptionInvoice";
import { useTermsAndConditionsAcknowledgement } from "src/sites/membership-purchase/hooks/useTermsAndConditionsAcknowledgement";
import { useTermsAndHelpLink } from "src/sites/membership-purchase/hooks/useTermsAndHelpLink";
import { toDollars } from "src/utils/toDollars";
import { PaymentBottomSheet } from "./PaymentBottomSheet";
import { PromoCodeModal } from "./PromoCodeModal";
import { ReferralCodeModal } from "./ReferralCodeModal";
import { useGetReferralCodeDiscount } from "../hooks/useGetReferralCodeDiscount";

interface PaymentPromoCode {
	promoCodeId: string;
	promoCode: string;
}

export const ProductPaymentScreen = ({
	setupIntentClientSecret,
	priceId,
	onGoBack,
}: {
	setupIntentClientSecret: string;
	priceId: number;
	onGoBack: () => void;
}) => (
	<StripeSetupIntentProvider setupIntentSecret={setupIntentClientSecret}>
		<PaymentScreen priceId={priceId} onGoBack={onGoBack} />
	</StripeSetupIntentProvider>
);

const PaymentScreen = ({
	priceId,
	onGoBack,
}: {
	priceId: number;
	onGoBack: () => void;
}) => {
	// STATES
	const [acknowledgementAccepted, setAcknowledgementAccepted] = useState(false);
	const [signatureBase64, setSignatureBase64] = useState<string | undefined>();
	const [showPaymentModal, setShowPaymentModal] = useState(false);

	const [showPromoCodeModal, setShowPromoCodeModal] = useState(false);
	const [paymentPromoCode, setPaymentPromoCode] = useState<
		PaymentPromoCode | undefined
	>();

	const [showReferralCodeModal, setShowReferralCodeModal] = useState(false);
	const [referralCode, setReferralCode] = useState<string | undefined>();
	// HOOKS
	const getInvoicePreview = usePreviewSubscriptionInvoice();
	const { userData, product } = useMembershipPurchaseContext();
	const { termsAndConditionsAcknowledgement } =
		useTermsAndConditionsAcknowledgement();
	const [termsAndConditionsTermsAndHelpLink] =
		useTermsAndHelpLink("TERMS_&_CONDITIONS");
	const { referralDiscount } = useGetReferralCodeDiscount();
	const normalizedReferralDiscount = useMemo(
		() => (referralDiscount ?? 0) * 100,
		[referralDiscount],
	);

	const getInvoicePreviewCallback = useCallback(async () => {
		if (!userData) return;
		return getInvoicePreview(
			userData.accountId,
			priceId,
			paymentPromoCode?.promoCodeId,
		);
	}, [userData, priceId, paymentPromoCode, getInvoicePreview]);

	const {
		data: invoicePreview,
		loading,
		retry: refetchInvoice,
	} = useFetchData(getInvoicePreviewCallback);

	const onValidPromoCodeSubmitted = (promoCodeId: string, code: string) => {
		setShowPromoCodeModal(false);
		setPaymentPromoCode({ promoCodeId, promoCode: code });
	};

	const onValidReferralCodeSubmitted = (code: string) => {
		setShowReferralCodeModal(false);
		setReferralCode(code);
	};

	useEffect(() => {
		if (paymentPromoCode) {
			refetchInvoice();
		}
	}, [paymentPromoCode, refetchInvoice]);

	const price = toDollars(
		product?.prices?.find((price) => price.id === priceId)?.metadata.Tiers[0]
			.AmountPerUnit ?? 0,
	);

	const paymentSubtotal = useMemo(() => {
		return (
			(invoicePreview?.invoice?.subtotal_excluding_tax ?? 0) -
			(invoicePreview?.invoice?.total_discount_amounts?.[0]?.amount ?? 0) -
			(referralCode ? normalizedReferralDiscount : 0)
		);
	}, [invoicePreview, referralCode, normalizedReferralDiscount]);

	const paymentTotal = useMemo(() => {
		return (
			(invoicePreview?.invoice?.total ?? 0) -
			(referralCode ? normalizedReferralDiscount : 0)
		);
	}, [invoicePreview, referralCode, normalizedReferralDiscount]);

	return (
		<>
			<AmpContainer width="full" height="full">
				<AmpContainer
					padding={4}
					direction="column"
					alignItems="center"
					width="full"
					color="surface"
					flexGrow={1}
				>
					<Grid container item direction="column" spacing={3} md={6} sm={12}>
						<Grid item>
							<AmpCard padding={6} gap={6} flexDirection="column">
								{loading ? (
									<LinearProgress />
								) : (
									<>
										<AmpText type="body_lg" fontWeight="bold">
											Subtotal
										</AmpText>
										<AmpContainer
											direction="row"
											justifyContent="space-between"
											width="full"
											alignItems="center"
										>
											<AmpText type="body_sm">{product?.name}</AmpText>
											<AmpText type="body_sm">{price}</AmpText>
										</AmpContainer>
										{paymentPromoCode && (
											<AmpContainer
												direction="row"
												justifyContent="space-between"
												width="full"
												alignItems="center"
											>
												<AmpText type="body_sm">
													Promotion ({paymentPromoCode.promoCode}):
												</AmpText>
												<AmpText type="body_sm">
													-
													{toDollars(
														invoicePreview?.invoice?.total_discount_amounts?.[0]
															?.amount ?? 0,
													)}
												</AmpText>
											</AmpContainer>
										)}
										{referralCode && (
											<AmpContainer
												direction="row"
												justifyContent="space-between"
												width="full"
												alignItems="center"
											>
												<AmpText type="body_sm">
													Referral ({referralCode}):
												</AmpText>
												<AmpText type="body_sm">
													-{toDollars(normalizedReferralDiscount)}
												</AmpText>
											</AmpContainer>
										)}
										<AmpContainer width="full" gap={2}>
											<AmpContainer
												direction="row"
												justifyContent="space-between"
												width="full"
												alignItems="center"
											>
												<AmpText type="body_sm">Subtotal:</AmpText>
												<AmpText type="body_sm">
													{toDollars(paymentSubtotal)}
												</AmpText>
											</AmpContainer>
											<AmpContainer
												direction="row"
												justifyContent="space-between"
												width="full"
												alignItems="center"
											>
												<AmpText type="body_sm">Tax:</AmpText>
												<AmpText type="body_sm">
													{toDollars(invoicePreview?.invoice?.tax ?? 0)}
												</AmpText>
											</AmpContainer>
											<AmpContainer
												direction="row"
												justifyContent="space-between"
												alignItems="center"
												width="full"
											>
												<AmpText type="body_sm">Total:</AmpText>
												<AmpText type="body_sm">
													{toDollars(paymentTotal)}
												</AmpText>
											</AmpContainer>
										</AmpContainer>
										<AmpContainer>
											{!paymentPromoCode && (
												<div onClick={() => setShowPromoCodeModal(true)}>
													<AmpText
														type="body_sm"
														color="primary"
														fontWeight="bold"
													>
														Add Promo Code
													</AmpText>
												</div>
											)}
											{!referralCode && (referralDiscount ?? 0) > 0 && (
												<div onClick={() => setShowReferralCodeModal(true)}>
													<AmpText
														type="body_sm"
														color="primary"
														fontWeight="bold"
													>
														Add Referral Code
													</AmpText>
												</div>
											)}
										</AmpContainer>
										<AmpDivider />
										<AmpContainer
											direction="row"
											justifyContent="space-between"
											alignItems="center"
										>
											<AmpText type="body_sm" fontWeight="bold">
												Due Today
											</AmpText>
											<AmpText type="body_sm" fontWeight="bold">
												{toDollars(paymentTotal)}
											</AmpText>
										</AmpContainer>
									</>
								)}
							</AmpCard>
						</Grid>
						<Grid item>
							<AmpCard padding={6} gap={6}>
								<AmpText type="body_sm">
									By{" "}
									{termsAndConditionsAcknowledgement?.signature_type ===
									EAcknowledgementSignature.Written
										? "signing"
										: "checking the box"}{" "}
									below you agree to our{" "}
									<a
										href={termsAndConditionsTermsAndHelpLink?.url__c}
										target="_blank"
										rel="noreferrer"
									>
										terms and conditions
									</a>
								</AmpText>
								{termsAndConditionsAcknowledgement?.signature_type ===
								EAcknowledgementSignature.Written ? (
									<AmpContainer width="full" alignItems="center">
										<SignaturePad
											onPadContentChange={(isEmpty) =>
												setAcknowledgementAccepted(!isEmpty)
											}
											onEnd={(base64ImageData) =>
												setSignatureBase64(base64ImageData)
											}
										/>
									</AmpContainer>
								) : (
									<AmpCheckbox
										checked={acknowledgementAccepted}
										onChange={(_, checked) =>
											setAcknowledgementAccepted(checked)
										}
										name=""
										color="secondary"
										label="Accept Terms and Conditions"
									/>
								)}
							</AmpCard>
						</Grid>
					</Grid>

					{userData?.stripeCustomerId && acknowledgementAccepted && (
						<PaymentBottomSheet
							customerId={userData?.stripeCustomerId}
							customerSignature={signatureBase64}
							priceId={priceId}
							open={showPaymentModal}
							referralCode={referralCode}
							onClose={() => setShowPaymentModal(false)}
						/>
					)}
				</AmpContainer>
				<AmpDivider />
				<AmpContainer width="full" alignItems="center" padding={4}>
					<Grid container direction="row" spacing={2} md={6} sm={12}>
						<Grid item xs={4}>
							<AmpButton
								label="Back"
								colorType="primary"
								type="outlined"
								onClick={onGoBack}
								width="wide"
							/>
						</Grid>
						<Grid item xs={8}>
							<AmpButton
								label="Checkout"
								disabled={!acknowledgementAccepted}
								onClick={() => setShowPaymentModal(true)}
								width="wide"
								colorType="secondary"
							/>
						</Grid>
					</Grid>
				</AmpContainer>
			</AmpContainer>
			<PromoCodeModal
				isOpen={showPromoCodeModal}
				onClose={() => setShowPromoCodeModal(false)}
				invoiceSubtotal={invoicePreview?.invoice?.subtotal_excluding_tax ?? 0}
				priceId={priceId}
				onValidPromoCodeSubmitted={onValidPromoCodeSubmitted}
			/>
			<ReferralCodeModal
				isOpen={showReferralCodeModal}
				onClose={() => setShowReferralCodeModal(false)}
				onValidReferallCodeSubmitted={onValidReferralCodeSubmitted}
			/>
		</>
	);
};
