import { getSpacing } from "../utils/get-spacing";
import styles from "./css/AmpContainer.module.scss";
import {
	IAmpContainerProps,
} from "./types/AmpContainerTypes";

export const AmpContainer = ({
	children,
	direction = "column",
	alignItems = "flex-start",
	justifyContent = "flex-start",
	wrap = "nowrap",
	gap = 0,
	padding,
	height,
	width,
	flex,
	flexGrow,
	flexShrink,
	color,
	overflow,
}: IAmpContainerProps) => {
	const getClassName = () => {
		const classes = [
			styles["amp-container"],
			styles[`direction-${direction}`],
			styles[`align-${alignItems}`],
			styles[`justify-${justifyContent}`],
			styles[`wrap-${wrap}`],
			styles[`width-${width}`],
			styles[`height-${height}`],
			styles[`flex-${flex}`],
			styles[`flex-grow-${flexGrow}`],
			styles[`flex-shrink-${flexShrink}`],
			styles[`container-color-${color}`],
			styles[`container-overflow-${overflow}`],
		];

		return classes.filter(Boolean).join(" ");
	};

	const style = {
		gap: getSpacing(gap) ?? undefined,
		padding: getSpacing(padding) ?? undefined,
	};

	return (
		<div className={getClassName()} style={style}>
			{children}
		</div>
	);
};
