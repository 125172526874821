import { DynamicSql } from "amp";
import { useFetchDataDynamic } from "./useFetchDataDynamic";

export const useGetAccountApproach = () => {
	const { data: generalInformation, loading } = useFetchDataDynamic(
		new DynamicSql("generalInformation").setColumns([
			"account_standard_approach__c",
		]),
	);
    const accountApproach = generalInformation?.[0]?.account_standard_approach__c;
	return {
		accountApproach,
		loading,
	};
};
